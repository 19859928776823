<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>
      <div class="sp-hero sp-bg-light-blue">
        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">

          <app-filter-apply-button
            :has-changes="filterStore.hasChanges"
            :is-initial-state="filterStore.isInitialState"
            :is-loading="loading"
            @click="applyFilter();"
            @reset="filterStore.$reset(); applyFilter();"
          ></app-filter-apply-button>

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="t('from')"
                  v-model="filterStore.filter.dateStart"
                  id="dateStart"
                  clearable
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="t('to')"
                  v-model="filterStore.filter.dateEnd"
                  id="dateEnd"
                  clearable
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <v-text-field
                  v-model="filterStore.filter.client"
                  filled
                  :label="t('name')"
                ></v-text-field>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <v-text-field
                  v-model="filterStore.filter.address"
                  filled
                  :label="t('address')"
                ></v-text-field>
              </div>
            </v-slide-item>

            <v-slide-item class="filter-reason-filter">
              <servapp-area-filter v-model="filterStore.filter.area"/>
            </v-slide-item>

            <v-slide-item class="filter-reason-filter">
              <servapp-status-filter v-model=" filterStore.filter.state"/>
            </v-slide-item>
          </v-slide-group>

        </v-container>
      </div>
    </div>
    <v-container fluid>

      <v-row class="data-table-toolbar"></v-row>

      <v-row v-if="dataLoaded && items.length === 0">
        <div class="empty-datatable">
          <v-icon>mdi-alert-circle-outline</v-icon>
          {{ t('no-data') }}
        </div>
      </v-row>

      <v-row v-else>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          class="elevation-0"
          item-key="unique_id"
          :sort-by="initialSortBy"
          :sort-desc="initialSortDesc"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-options': [25, 50, 100, 200],
            pageText: '{0}-{1} von {2}',
            'items-per-page-text': t('table.items-per-page')
          }"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span class="ellipsis"><b>{{ item.date }}</b></span>
          </template>

          <template v-slot:[`item.time`]="{ item }">
            <span class="ellipsis">{{ formatTime(item) }}</span>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <span class="ellipsis">{{ item.name }}</span>
          </template>

          <template v-slot:[`item.area`]="{ item }">
            <span class="ellipsis">{{ item.area }}</span>
          </template>

          <template v-slot:[`item.zip_code`]="{ item }">
            <span class="ellipsis">{{ item.zip_code }} {{ item.city }}</span>
          </template>

          <template v-slot:[`item.address`]="{ item }">
            <span class="ellipsis">{{ item.address }}</span>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <span class="ellipsis">{{ item.email }}<br/>{{ item.phone }}</span>
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <span class="ellipsis"><b>{{ $t('servappt.data.responses.states.' + item.state) }}</b></span>
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <div v-show="item.state == 'unconfirmed'">
            <router-link :to="{ path: '/servappt/appointments/create/' + item.id }" class="edit-row-table">
              <v-icon
                small
                class="edit-row-button"
              >
                edit
              </v-icon>
            </router-link>
            </div>
          </template>
        </v-data-table>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import moment from 'moment'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import ServappAreaFilter from '@/components/filter-components/ServapptAreaFilter'
import ServappStatusFilter from '@/components/filter-components/ServapptResponseStateFilter'
import { useServapptResponseFilterStore } from '@/stores/ServapptResponseFilterStore'

export default {
  name: 'ServapptResponsesIndex',
  components: {
    ServappAreaFilter,
    ServappStatusFilter,
    toolbar,
    AppFilterApplyButton,
    ContentFilterEmployee,
    AppFilterDatePicker,
    AppFilterSearchField
  },
  setup() {
    const filterStore = useServapptResponseFilterStore()
    return { filterStore }
  },
  data() {
    return {
      items: [],
      totalItems: 0,
      dataLoaded: false,
      error: false,
      loading: false,
      options: {},
      initialSortBy: 'id',
      initialSortDesc: false
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.date'),
          align: 'start',
          sortable: true,
          value: 'date'
        },
        {
          text: this.t('table.time'),
          align: 'start',
          sortable: false,
          value: 'time'
        },
        {
          text: this.t('table.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.t('table.area'),
          align: 'start',
          sortable: true,
          value: 'area'
        },
        {
          text: this.t('table.zip_code'),
          align: 'start',
          sortable: true,
          value: 'zip_code'
        },
        {
          text: this.t('table.address'),
          align: 'start',
          sortable: true,
          value: 'address'
        },
        {
          text: this.t('table.contact'),
          align: 'start',
          sortable: true,
          value: 'email'
        },
        {
          text: this.t('table.state'),
          align: 'start',
          sortable: true,
          value: 'state'
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getItems()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('servappt.responses.list.' + key);
    },
    buildFilter() {
      let filter = this.filterStore.filter;
      return {
        from: filter.dateStart,
        to: filter.dateEnd,
        userid: filter.selectedUser?.id,
        state: filter.state,
      }
    },
    applyFilter() {
      this.filterStore.filterApplied()
      this.getItems()
    },
    prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.filterStore.filter.dateStart) {
        queryStringParts.push('dateStart=' + this.filterStore.filter.dateStart)
      }
      if (this.filterStore.filter.dateEnd) {
        queryStringParts.push('dateEnd=' + this.filterStore.filter.dateEnd)
      }
      if (this.filterStore.filter.area) {
        queryStringParts.push('area=' + this.filterStore.filter.area)
      }
      if (this.filterStore.filter.state.length > 0) {
        queryStringParts.push('st=' + this.filterStore.filter.state)
      }
      if (this.filterStore.filter.address.length > 0) {
        queryStringParts.push('address=' + this.filterStore.filter.address)
      }
      if (this.filterStore.filter.client.length > 0) {
        queryStringParts.push('client=' + this.filterStore.filter.client)
      }
      if (sortBy) {
        queryStringParts.push('sortBy[]=' + (sortBy[0] ?? ''))
        queryStringParts.push('sortDesc[]=' + sortDesc[0])
      }
      page = page ? page : 1
      queryStringParts.push('page=' + page)
      queryStringParts.push('itemsPerPage=' + itemsPerPage)
      return '?' + queryStringParts.join('&')
    },
    apiCall() {
      return new Promise((resolve, reject) => {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options

        let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

        HTTP.get('servappt/responses' + url).then(function (response) {
          let items = [];
          let item_counter = 0;

          response.data.data.forEach(item => {
            item.unique_id = item_counter++;
            items.push(item);
          });
          
          let total = response.data.total;

          resolve({
            items,
            total,
          })
        }.bind(this))

      })
    },
    getItems() {
      if (!this.loading) {
        this.loading = true
        this.dataLoaded = false

        this.apiCall().then(data => {
          this.items = data.items
          this.totalItems = data.total
          this.loading = false
          this.error = false
        })
      }
    },
    formatTime(item) {
      if (item.morning == 1 && item.afternoon == 1) {
        return this.t('time.full_day')
      } else if (item.morning == 1) {
        return this.t('time.morning')
      } else if (item.afternoon == 1) {
        return this.t('time.afternoon')
      } else {
        return this.t('time.none')
      }
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm')
    }
  }
}
</script>

<style scoped lang="scss">
.sp-hero .filter-status-filter {
  width: calc(50% - 134px);
}

.ellipsis {
  display: block;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.status-filter {
  position: relative;
  float: right;
  max-width: calc(50% - 12px);
  min-height: 48px;
  margin: 4px 0 24px;
  padding-top: 6px;
}

.status-filter.extended {
  padding-right: 160px;
}

.status-filter button {
  position: absolute;
  top: 12px;
  right: 0;
}

.comment {
  margin-right: 10px;
}
</style>


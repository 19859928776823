<template>
  <tr :class="getClass">
    <td v-for="(header, index) in headers" :key="index" :style="'width: ' + header.width + 'px; padding: 0px; margin:0px;'">
      <slot :item="item" :name="columnName(header)">
        <div
          v-if="header.value == 'drag'"
          :style="getAlignment(header)"
          style="cursor:move;"
          class="drag-handle fill-td td-align-right"
        >
          <v-icon>
            mdi-drag
          </v-icon>
        </div>

        <div
          v-if="header.value == 'icon'"
          :style="getAlignment(header)"
          style="cursor:move;"
          class="drag-handle fill-td td-align-center"
        >
          <v-icon>
            {{ getNonSlotValue(item, header) }}
          </v-icon>
        </div>

        <div
          v-if="header.value == 'title'"
          :style="getAlignment(header)"
          style="cursor:move;"
          class="drag-handle fill-td td-align-left"
        >
          <div class="pl-4">{{ getNonSlotValue(item, header) }}</div>
        </div>

        <div v-if="header.value == 'type'" :style="getAlignment(header)">
          <v-icon v-if="getNonSlotValue(item, header) =='webview'" :title="$t('menu.list.webview')">share</v-icon>
          <v-icon v-if="getNonSlotValue(item, header) =='external_browser'" :title="$t('menu.list.external_browser')">share</v-icon>
          <v-icon v-if="getNonSlotValue(item, header) =='content_block'" :title="$t('menu.list.content_block')">mdi-view-dashboard</v-icon>
          <v-icon v-if="getNonSlotValue(item, header) =='module'" :title="$t('menu.list.module')">mdi-checkbox-blank-badge</v-icon>
        </div>

        <div v-if="header.value == 'edit'" :style="getAlignment(header)">
          <router-link :to="{ name: 'MenuEdit', params: { id: item.id } }" class="edit-row-table">
            <v-icon
              small
              class="edit-row-button"
            >
              edit
            </v-icon>
          </router-link>
        </div>

        <div v-if="header.value == 'delete'" :style="getAlignment(header)">
          <v-icon
            small
            class="edit-row-button"
            @click="deleteItem(item)"
          >
            delete
          </v-icon>
        </div>
      </slot>
    </td>
  </tr>
</template>
  
<script>
  import { EventBus } from '@/event-bus'
   
  export default {
    name: "DataTableRowHandler",
    components: {},
    props: {
      itemClass: {
        type: String,
        default: "",
      },
      item: {
        type: Object,
        default: () => {
          return {}
        },
      },
      headers: {
        type: Array,
        default: () => {
          return []
        },
      },
    },
    data() {
      return {}
    },
    computed: {
      getClass() {
        return this.itemClass
      }
    },
    methods: {
      columnName(header) {
        return `item.${header.value}`
      },
      getAlignment(header) {
        const align = header.align ? header.align : "center"
        return `text-align: ${align}`
      },
      getNonSlotValue(item, header) {
        const val = item[header.value]

        if (val) {
          return val
        }
  
        return ""
      },
      deleteItem(item) {
        EventBus.$emit('delete-item', item)
      }
    }
  }
</script>

<style scoped lang="scss">
.fill-td {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;

  &.td-align-left {
    justify-content: flex-start;
  }

  &.td-align-center {
    justify-content: center;
  }

  &.td-align-right {
    justify-content: flex-end;
  }
}
</style>
  
<template>
    <div>
        <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>
    
        <v-container fluid class="edit-screen">
  
            <loading :error="error" :dataLoaded="dataLoaded"></loading>
      
            <v-row v-if="dataLoaded" class="settings-wrapper">
    
                <v-col class="pr-0">
                    <v-card elevation="0">
                        <v-card-title>{{ t('group-title') }}</v-card-title>
                        <v-card-text>
                            <div class="button-wrapper">                
                                <v-btn 
                                    elevation="0"
                                    color="primary"
                                    class="icon-left" 
                                    data-cy="createButton"
                                    @click="openSettingDialog(null)"
                                    :disabled="saving || loading"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                    {{ t('create') }}
                                </v-btn>
                            </div>
            
                            <v-data-table
                                v-if="dataLoaded"
                                :headers="headers"
                                :items="settings.expenseTypes"
                                :items-per-page="99"
                                :no-data-text="t('table.no-data')"
                                class="elevation-0"
                                hide-default-footer
                            >
                                <template #body="props">
                                    <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
                                    <draggable
                                        v-else
                                        v-model="settings.expenseTypes"
                                        tag="tbody"
                                    >
                                        <data-table-expense-types-row-handler
                                            v-for="(item, index) in props.items"
                                            :row-index="index"
                                            :item="item"
                                            :headers="headers"
                                        ></data-table-expense-types-row-handler> 
                                    </draggable>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
        
                <v-col class="pl-0">
                    <v-form ref="form2">
                        <v-card elevation="0">
                            <v-card-title>{{ t('recipient') }}</v-card-title>
                            <v-card-text>
                              <v-form ref="formEmail">
                                <v-switch
                                  v-model="settings.expenseEmail.expense_email_created_enabled"
                                  inset
                                  :label="settings.expenseEmail.expense_email_created_enabled ?  t('yes') : t('no')"
                                  @change="handleExpenseEmailSwitch($event)"
                                ></v-switch>
                
                                <br>
            
                                <v-text-field
                                  :label="t('email')"
                                  v-model="settings.expenseEmail.expense_email_created_to"
                                  filled
                                  :disabled="!settings.expenseEmail.expense_email_created_enabled"
                                  :rules="[
                                      v => !settings.expenseEmail.expense_email_created_enabled || (!!v && validateEmail(v)) || t('email-recipient-notvalid')
                                  ]"
                                  hide-details="auto"
                                ></v-text-field>
                              </v-form>
                            </v-card-text>
                        </v-card>
                    </v-form>
                </v-col>
            </v-row>
  
        </v-container>
  
        <transition name="fade">
            <div class="bottom-navigation modal-nav">
                <v-container fluid>
                    <v-row>
                        <v-btn
                          color="primary"
                          text
                          class="icon-left"
                          @click="reset()"
                          :disabled="saving"
                        >
                          <v-icon>mdi-reload</v-icon>
                          {{ t('reset') }}
                        </v-btn>

                        <v-spacer></v-spacer>
                        
                        <v-btn
                          color="primary"
                          class="icon-left"
                          @click="saveSettings()"
                          :disabled="!hasChanges || loading || !dataLoaded"
                          :loading="saving"
                        >
                          <v-icon>mdi-check</v-icon>
                          {{ t('save') }}
                        </v-btn>
                    </v-row>
                </v-container>
            </div>
        </transition>
  
        <v-dialog
          v-model="settingsDialog"
          content-class="settings-dialog"
          width="600"
          persistent
        >
            <v-card>
                <v-card-title>
                  {{ settingsDialog && editItem.id ? t('dialog.dialog-title-edit') : t('dialog.dialog-title-add') }}
                </v-card-title>
                <v-card-text v-if="settingsDialog">
                    <v-form ref="form" v-on:submit.prevent="editItem.id ? editSettings() : addSettings()">
                        <v-text-field
                          :label="t('dialog.name')"
                          v-model="editItem.name"
                          counter="40"
                          filled
                          :rules="[
                              () => !!editItem.name || $t('validation.required.default'),
                              () => (editItem.name && editItem.name.length <= 40) || $t('validation.length.dynamic', { maxLength: 40})
                          ]"
                        ></v-text-field>

                        <v-text-field
                          :label="t('dialog.code')"
                          v-model="editItem.fs_code"
                          counter="40"
                          :rules="[
                              () => editItem.fs_code.length <= 40 || $t('validation.length.dynamic', { maxLength: 40})
                          ]"
                          filled
                        ></v-text-field>

                        {{ t("dialog.type") }}

                        <v-radio-group v-model="editItem.calculation_type_code">
                            <v-radio
                              :label="t('dialog.AT_COST')"
                              value="AT_COST"
                            ></v-radio>
            
                            <v-radio
                              :label="t('dialog.HOURLY_RATE')"
                              value="HOURLY_RATE"
                            ></v-radio>
            
                            <v-radio
                              :label="t('dialog.DAILY_RATE')"
                              value="DAILY_RATE"
                            ></v-radio>
            
                            <v-radio
                              :label="t('dialog.FACTOR')"
                              value="FACTOR"
                            ></v-radio>
                        </v-radio-group>

                        <div v-if="editItem.calculation_type_code === 'FACTOR'">
                          <v-text-field
                            class="mt-3"
                            :label="t('dialog.unit')"
                            v-model="editItem.unit"
                            counter="10"
                            :rules="[
                              () => !!editItem.unit || $t('validation.required.default'),
                              () => (editItem.unit && editItem.unit.length <= 10) || $t('validation.length.dynamic', { maxLength: 10})
                            ]"
                            filled
                          ></v-text-field>
                        </div>

                        <v-checkbox
                          v-model="editItem.is_active"
                          :label="t('dialog.active')"
                          hide-details="auto"
                        ></v-checkbox>

                        <p class="card-subtitle">{{ t('dialog.reimbursement-group-title') }}</p>

                        <v-checkbox
                          class="mt-0"
                          :label="t('dialog.is_reimbursed')"
                          v-model="editItem.is_reimbursed"
                          hide-details="auto"
                        ></v-checkbox>

                        <v-text-field
                          v-if="showReimburseFactor"
                          class="mt-3"
                          :label="t('dialog.employee_factor') + '*'"
                          v-model="editItem.employee_factor"
                          filled
                          type="number"
                          step="0.01"
                          :rules="[() => validateReimbursementFactor]"
                          hide-details="auto"
                        ></v-text-field>

                        <p class="card-subtitle">{{ t('dialog.documents-group-title') }}</p>

                        <v-checkbox
                          class="mt-0"
                          :label="t('dialog.requires_receipt')"
                          v-model="editItem.requires_receipt"
                          hide-details="auto"
                        ></v-checkbox>

                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                      elevation="0"
                      color="primary"
                      text
                      class="icon-left px-4"
                      @click="closeSettingsDialog()"
                    >
                      <v-icon>mdi-close</v-icon>
                      {{ t( 'dialog.cancel') }}
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                      elevation="0"
                      color="primary"
                      class="icon-left px-4"
                      @click="settingsDialog && editItem.id ? editSettings() : addSettings()"
                    >
                      <v-icon>mdi-check</v-icon>
                      {{ settingsDialog && editItem.id ? t('dialog.update') : t('dialog.add') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
  
    </div>
</template>
  
<script>
  import { HTTP } from '@/auth'
  import { EventBus } from '@/event-bus.js'
  import toolbar from '@/components/layouts/Navigation'
  import loading from '@/components/layouts/Loading'
  import draggable from 'vuedraggable'
  import DataTableExpenseTypesRowHandler from '@/components/DataTableExpenseTypesRowHandler'
  
  export default {
  name: 'SettingsExpenses',
  components: {
    toolbar,
    loading,
    DataTableExpenseTypesRowHandler,
    draggable
  },
  data() {
    return {
      settings: {
        expenseTypes: [],
        expenseEmail: {},
      },
      dataOriginal: {},
      dataLoaded: false,
      loading: false,
      saving: false,
      error: false,
      settingsDialog: false,
      editItem: {
        is_reimbursed: false,
      },
      editItemIndex: null,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'drag',
          width: 30
        },
        {
          text: this.t('table.name'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: this.t('table.status'),
          align: 'start',
          sortable: false,
          value: 'status',
          width: 100
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 80
        }
      ]
    },
    hasChanges() {
      return JSON.stringify(this.settings) !== JSON.stringify(this.dataOriginal)
    },
    showReimburseFactor() {
      return this.settingsDialog && this.editItem && this.editItem.is_reimbursed && this.editItem.calculation_type_code !== 'AT_COST'
    },
    validateReimbursementFactor() {
      if (!this.showReimburseFactor) {
        return true
      }
      if (this.editItem.employee_factor) {
        return true
      }
      return this.$t('errors.rules.required', { 0: this.t('dialog.employee_factor') })
    },
  },
  created() {
    this.getSettings()
    EventBus.$on('et-open-settings-dialog', (index, item) => {
      this.openSettingDialog(item, index)
      this.settingsDialog = true
    })
  },
    methods: {
      t: function (key) {
        return this.$t('expenses.types.' + key);
      },
      getExpenseTypes() {
        return new Promise(function(resolve, reject) {
          HTTP.get('expense-types').then((response) => {
            resolve({
              'type': 'expenseType',
              'data': response.data
            })
          }).catch((error) => {
            reject(error)
          })
        }.bind(this))  
      },
      getExpenseEmail() {
        return new Promise(function(resolve, reject) {
          HTTP.get('expense-settings').then((response) => {
            resolve({
              'type': 'expenseEmail',
              'data': response.data
            })
          }).catch((error) => {
            reject(error)
          })
        }.bind(this))  
      },
      getSettings() {
        this.loading = true
  
        Promise.allSettled([
          this.getExpenseTypes(),
          this.getExpenseEmail()
        ]).then(function(responses) {
  
          let errors = []
          responses.forEach((response) => {
            if (response.status == 'fulfilled') {
              if (response.value.type === 'expenseType') {
                this.settings.expenseTypes = response.value.data
              } else {
                this.settings.expenseEmail = response.value.data
              }
            }
            if (response.status == 'rejected') {
              errors.push(this.t('http.request-get-error'))
            }
          })
  
          if (errors.length > 0) {
            this.$root.infoNotification.showMessage(errors)
          }
  
          this.dataOriginal = JSON.parse(JSON.stringify(this.settings))
  
          this.dataLoaded = true
          this.loading = false
          this.error = false
  
        }.bind(this)).finally(function() {
          this.saving = false
        }.bind(this))
      },
      saveExpenseTypes() {
        return new Promise(function(resolve, reject) {
          HTTP.post('expense-types', this.settings.expenseTypes).then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(error)
          }) 
        }.bind(this))
      },
      saveExpenseEmail() {
        return new Promise(function(resolve, reject) {
          HTTP.post('expense-settings', this.settings.expenseEmail).then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(error)
          }) 
        }.bind(this))
      },
      saveSettings() {
        if (this.$refs.formEmail.validate()) {
          this.saving = true
    
          Promise.allSettled([
            this.saveExpenseTypes(),
            this.saveExpenseEmail()
          ]).then(function(responses) {
    
            let errors = []
            responses.forEach((response) => {
              if (response.status == 'rejected') {
                errors.push(this.t('http.request-post-error'))
              }
            })
    
            if (errors.length > 0) {
              this.$root.infoNotification.showMessage(errors, true)
            } else {
              this.$root.infoNotification.showMessage(this.t('http.success-msg-saved'), false)
            }
            
            this.getSettings()
    
          }.bind(this)).finally(function() {
            this.saving = false
          }.bind(this))
        }
      },
      reset() {
        this.dataLoaded = false
        this.settings.expenseTypes = []
        this.getSettings()
      },
      openSettingDialog(item, index) {
        if (item) {
          this.editItem = JSON.parse(JSON.stringify(item))
          this.editItemIndex = index
        } else {
          this.editItem = {
            id: null,
            name: '',
            fs_code: '',
            name_code: '',
            requires_amount: false,
            requires_hours: false,
            unit: '',
            requires_quantity: false,
            requires_receipt: false,
            calculation_type_code: '',
            is_reimbursed: false,
            employee_factor: 0,
            is_active: true
          }
        }
        this.settingsDialog = true
      },
      closeSettingsDialog() {
        this.settingsDialog = false
        this.$refs.form.reset()
        this.$nextTick(() => {
          this.editItem = null
          this.editItemIndex = null
        })
      },
      addSettings() {
        if (this.$refs.form.validate()) {
          this.settings.expenseTypes.push({
            id: -1,
            fs_code: this.editItem.fs_code,
            name_code: this.editItem.name_code,
            requires_amount: this.editItem.requires_amount,
            requires_hours: this.editItem.requires_hours,
            unit: this.editItem.unit,
            requires_quantity: this.editItem.requires_quantity,
            requires_receipt: this.editItem.requires_receipt,
            calculation_type_code: this.editItem.calculation_type_code,
            is_reimbursed: this.editItem.is_reimbursed,
            employee_factor: this.editItem.employee_factor,
            name: this.editItem.name,
            is_active: this.editItem.is_active,
          })
          this.closeSettingsDialog()
        }
      },
      editSettings() {
        if (this.$refs.form.validate()) {
          Object.assign(this.settings.expenseTypes[this.editItemIndex], this.editItem)
          this.closeSettingsDialog()
        }
      },
      async getData() {
        let response = await HTTP.get('/expense-settings')
        this.settings.expense_email_created_enabled = response.data.expense_email_created_enabled
        this.settings.expense_email_created_to = response.data.expense_email_created_to
        this.settingsOriginal = JSON.parse(JSON.stringify(this.settings));
        this.dataLoaded = true
      },
      handleExpenseEmailSwitch($event) {
        if (!$event) {
          this.$refs.formEmail.resetValidation()
        }
      },
      validateEmail(v) {
        let isValid = true
        let emails = v.split(';')
        for (let email of emails) {
          if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(email)) {
            isValid = false
            break
          }
        }
        return isValid
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .table-loader {
    padding: 0 !important;
    height: auto !important;
  }
  
  .settings-wrapper {
    justify-content: space-between;
  
    .col {
      flex-basis: unset;
      flex-grow: unset;
    }
  
    .col:first-child {
        width: calc(66.66% - 16px);
    }
  
    .col:last-child {
        width: calc(33.33% - 16px);
        align-self: flex-start;
    }
  
    .v-data-table {
        margin-bottom: 0;
    }
  }

  .card-subtitle {
    font-size: 16px;
    font-weight: bold;
    margin: 32px 0 8px 0 !important;
  }
  
  .button-wrapper {
    text-align: right;
    padding-bottom: 16px;
  }
  
  .checkbox{
    position: absolute;
    padding-top: 7px !important;
    margin-top: 0 !important;
}
</style>
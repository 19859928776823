<template>
  <div>
    <basic-edit-container
      :title="t('document.title-edit')"
      :has-changes="hasChanges"
      :data-loaded="dataLoaded"
      :delete-action="deleteItem"
      :save-action="save"
      @saved="onSaved"
      @cancel="cancel"
    >
      <template v-slot:default>

        <v-card elevation="0">
          <v-card-title>{{ t('document.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              :label="t('document.document-name')"
              counter="120"
              v-model="item.title"
              filled
              :rules="[
                () => !!item.title || $t('validation.required.default'),
                () => (item.title && item.title.length <= 120) || $t('validation.length.default')
              ]"
            ></v-text-field>

            <v-text-field
              :label="t('document.description')"
              counter="120"
              v-model="item.description"
              filled
              :rules="[
								() => (!item.description || (item.description && item.description.length <= 120)) || $t('validation.length.description')
							]"
            ></v-text-field>

            <div v-if="item.url" class="existing-file">
              <v-icon>
                mdi-paperclip
              </v-icon>
              <span>{{ item.filename }} ( {{ item.filesize }}kb )</span>
              <v-icon @click="item.url = ''">
                delete
              </v-icon>
            </div>

            <v-file-input
              v-else
              :label="t('document.choose-file')"
              truncate-length="40"
              show-size
              filled
              v-model="document"
              :rules="[
                () => !!document || $t('validation.required.default')
              ]">
            </v-file-input>

            <v-text-field
              :label="t('document.keywords')"
              v-model="item.keywords"
              filled
              :hint="t('document.keywords-hint')"
              class="has-hint-message"
              hide-details
            ></v-text-field>
          </v-card-text>
        </v-card>

      </template>

      <template v-slot:meta>

        <v-card elevation="0">
          <v-card-title>{{ $t('components.permissiongroup-select.label') }}</v-card-title>
          <v-card-text>
            <permission-group-selector
              v-model="item.assigned_permission_groups"
              :required="true"
              mode="dropdown"
            ></permission-group-selector>
          </v-card-text>
        </v-card>

        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('move-document.group-title') }}</v-card-title>
          <v-card-text>
            <div>
              <v-btn
                  elevation="0"
                  color="primary"
                  class="icon-left"
                  @click="dialogVisible = true"
              >
                <v-icon>mdi-folder-search-outline</v-icon>
                {{ t('move-document.choose-folder') }}
              </v-btn>
              <p v-if="moveToDir" class="sp-mar-top-2">{{ t('move-document.will-be-moved') }}: <b>{{ moveToDir.name }}</b></p>
            </div>
          </v-card-text>
        </v-card>

      </template>
    </basic-edit-container>

    <MoveToFolderDialog
      v-if="dataLoaded"
      :dialog="dialogVisible"
      :root-name="$t('documents.list.title')"
      :parent-folder-id="item.parent_folder_id"
      get-url="documents/folders/all"
    ></MoveToFolderDialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import PermissionGroupSelector from '@/components/PermissionGroupSelector'
import MoveToFolderDialog from '@/components/dialogs/MoveToFolderDialog'

export default {
  name: 'DocumentsEditDocument',
  components: {   
    BasicEditContainer,
    PermissionGroupSelector,
    MoveToFolderDialog
  },
  data() {
    return {
      item: {
        title: '',
        description: '',
        keywords: '',
        filename: '',
        assigned_permission_groups: [],
      },
      currentDocumentId: null,
      document: null,
      dataOriginal: '',
      moveToDir: null,
      dataLoaded: false,
      dialogVisible: false
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.currentDocumentId = this.$route.params.id
    }
    await this.getDocument()
    this.dataLoaded = true
  },
  mounted() {
    EventBus.$on('move-to-dir-select', (folder) => {
      this.moveToDir = folder
      this.dialogVisible = false
    })
  },
  beforeDestroy() {
    EventBus.$off('set-moveToDir')
  },
  computed: {
    hasChanges() {
      if (!this.dataOriginal) {
        return false;
      }
      return (JSON.stringify(this.item) !== JSON.stringify(this.dataOriginal))
          || this.moveToDir != null
          || this.document != null
    },
  },
  methods: {
    t: function(key) {
      return this.$t('documents.detail.' + key)
    },
    async getDocument() {
      let response = await HTTP.get('documents/' + this.currentDocumentId)
      this.item = response.data
      // Prepare permission groups for select input
      const tempPermGroupsIds = []
      this.item.assigned_permission_groups.forEach((element) => {
        tempPermGroupsIds.push(element.permission_group_id)
      })
      this.item.assigned_permission_groups = tempPermGroupsIds
      this.item.filesize = (this.item.filesize / 1000).toFixed(2)

      this.dataOriginal = JSON.parse(JSON.stringify(this.item))
    },
    save() {
      if (this.moveToDir) {
        this.item.move_to_folder = this.moveToDir.folder_id ?? -1
      }

      let formData = new FormData()
      formData.append('data', JSON.stringify(this.item))
      if (this.document) {
        formData.append('document', this.document)
      }
      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }

      return HTTP.patch('documents/' + this.item.id, formData, config)
    },
    close() {
      this.$router.back()
    },
    cancel() {
      this.close()
    },
    async deleteItem() {
      await HTTP.delete('documents/' + this.itemId)
      this.close()
    },
    onSaved() {
      this.close()
    }
  }
}
</script>

<style scoped lang="scss">
.existing-file {
  margin-bottom: 30px;

  .mdi-paperclip {
    margin-top: 1px;
  }

  span {
    display: inline-block;
    width: calc(100% - 66px);
    background: #F5F5F5;
    color: #000000;
    padding: 17px 12px;
    margin: 0 9px;
    border-radius: 4px;
  }
}
</style>

import i18n from '@/i18n'

export function getFilterStatus() {  
  return [
    {
      name: i18n.t('components.select-status-filter-widget.status.active'),
      icon: 'mdi-eye',
      slug: 'active',
      className: 'status-active'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.ready'),
      icon: 'mdi-check-circle',
      slug: 'ready',
      className: 'status-ready'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.completed'),
      icon: 'mdi-check-all',
      slug: 'completed',
      className: 'status-completed'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.approved'),
      icon: 'mdi-check-circle',
      slug: 'approved',
      className: 'status-approved'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.done'),
      icon: 'mdi-check-circle',
      slug: 'done',
      className: 'status-done'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.cleared'),
      icon: 'mdi-format-clear',
      slug: 'cleared',
      className: 'status-cleared'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.finished'),
      icon: 'mdi-check-all',
      slug: 'finished',
      className: 'status-finished'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.filed'),
      icon: 'mdi-file-chart-check-outline',
      slug: 'filed',
      className: 'status-filed'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.invoiced'),
      icon: 'mdi-check-all',
      slug: 'invoiced',
      className: 'status-invoiced'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.published'),
      icon: 'mdi-check-circle',
      slug: 'published',
      className: 'status-published'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.open'),
      icon: 'mdi-clock',
      slug: 'open',
      className: 'status-open'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.planned'),
      icon: 'mdi-clock',
      slug: 'planned',
      className: 'status-planned'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.processing'),
      icon: 'mdi-clock',
      slug: 'processing',
      className: 'status-processing'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.running'),
      icon: 'mdi-clock',
      slug: 'running',
      className: 'status-running'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.submitted'),
      icon: 'mdi-clock',
      slug: 'submitted',
      className: 'status-submitted'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.reimbursed'),
      icon: 'mdi-account-arrow-up-outline',
      slug: 'reimbursed',
      className: 'status-reimbursed'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.cancelled'),
      icon: 'mdi-close-circle',
      slug: 'cancelled',
      className: 'status-cancelled'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.declined'),
      icon: 'mdi-close-circle',
      slug: 'declined',
      className: 'status-declined'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.overdue'),
      icon: 'mdi-alert-circle',
      slug: 'overdue',
      className: 'status-overdue'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.rejected'),
      icon: 'mdi-card-bulleted-off-outline',
      slug: 'rejected',
      className: 'status-rejected'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.new'),
      icon: 'mdi-plus-circle-multiple-outline',
      slug: 'new',
      className: 'status-new'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.draft'),
      icon: 'mdi-pencil-outline',
      slug: 'draft',
      className: 'status-draft'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.exported'),
      icon: 'mdi-export',
      slug: 'exported',
      className: 'status-exported'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.inactive'),
      icon: 'mdi-eye-off',
      slug: 'inactive',
      className: 'status-inactive'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.archived'),
      icon: 'mdi-archive-arrow-down',
      slug: 'archived',
      className: 'status-archived'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.delivered'),
      icon: 'mdi-truck-check-outline',
      slug: 'delivered',
      className: 'status-delivered'
    },
    {
      name: i18n.t('components.select-status-filter-widget.status.deleted'),
      icon: 'mdi-trash-can',
      slug: 'deleted',
      className: 'status-deleted'
    }
  ]
}
<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      max-width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          text
          class="icon-left"
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          data-cy="cancel"
          @click="cancel()"
        >
          <v-icon>mdi-close</v-icon>
          {{ $t('components.cancel-button.label') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">
          {{ $t('components.cancel-button.confirmation-question-title') }}
        </v-card-title>
        <v-card-text>{{ $t('components.cancel-button.confirmation-question-message') }}</v-card-text>
        <v-card-actions>
          <v-btn
            text
            data-cy="unconfirm"
            @click="confirm(false)"
            class="delete icon-left"
          >
            <v-icon>mdi-close</v-icon>
            {{ $t('components.cancel-button.no') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            data-cy="confirm"
            @click="confirm(true)"
            class="delete icon-left"
          >
            <v-icon>mdi-check</v-icon>
            {{ $t('components.cancel-button.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'CancelButtonDialog',
  data() {
    return {
      show: false,
      cont: null,
      confirmed: false,
    }
  },
  props: {
    goToName: String,
    dataEdited: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.dataEdited && !this.confirmed) {
      const promise = new Promise(function (resolve, reject) {
        this.cont = resolve
        this.show = true
      }.bind(this))
      promise
          .then(function () {
            this.cont = null
            next();
          }.bind(this));
    } else {
      this.confirmed = false
      next()
    }
  },
  methods: {
    goTo() {
      if (!this.goToName) {
        this.$emit("cancel")
      } else {
        this.$router.push({ name: this.goToName })
      }
    },
    cancel() {
      if (this.dataEdited) {
        this.show = true
        return
      }
      this.goTo()
    },
    confirm(userConfirmed) {
      this.$emit('cancelEditConfirmed', userConfirmed)
      if (userConfirmed) {
        this.confirmed = true
        if (this.cont) {
          this.cont()
        } else {
          this.goTo()
        }
      } else {
        this.show = false
      }
    }
  }
}
</script>

<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <div class="breadcrumbs-bar">
      <v-container fluid>
          <span v-if="hasCurrentFolder" class="breadcrumb-item" :dirPath="null" @click="getDirectories(null)">{{ t('title') }}</span>
          <span v-else class="breadcrumb-item inactive">{{ t('title') }}</span>
          <span v-show="!loading" class="breadcrumb-item" v-for="(value, index) in breadcrumbs" :key="index" v-bind:dirPath="value.id" @click="getDirectories(value)">
            <span> / {{ value.title }}</span>
          </span> 
          <span v-if="!loading && hasCurrentFolder" class="breadcrumb-item" :dirPath="null"> / <span class="current-folder">{{ currentFolder.title }}</span></span>
      </v-container>
    </div>

    <v-container fluid class="edit-screen">
      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left mr-4"
            outlined
            :to="createNewEntry('folder')"
          >
            <v-icon>mdi-folder-plus</v-icon>
            {{ t('create-folder') }}
          </v-btn>
        </div>
        <div>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            outlined
            :to="createNewEntry('document')"
          >
            <v-icon>mdi-file-upload-outline</v-icon>
            {{ t('upload-file') }}
          </v-btn>
        </div>
        <div>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="createNewEntry('instruction')"
          >
            <v-icon>mdi-plus</v-icon>
            {{ t('create-instruction') }}
          </v-btn>
        </div>
      </v-row>

      <v-row v-if="dataLoaded && folders.length === 0">
        <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
        </div>
      </v-row>

      <v-row v-else>

        <v-data-table
          calculate-widths
          :server-items-length="totalInstructions"
          hide-default-footer
          v-model="selected"
          :headers="headers"
          :items="folders"
          class="elevation-0"
        >  
          <template #body="props">
            <tbody v-if="loading"><tr><td colspan="7" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
            <draggable
              v-else
              handle=".drag-handle"
              :list="props.items"
              tag="tbody"
              :disabled="!allowDrag"
              :move="onMoveCallback"
              :clone="onCloneCallback"
              @end="onDropCallback(props.items)"
            >
              <data-table-instruction-row-handler
                v-for="(item, index) in props.items"
                :row-index="index"
                :item="item"
                :headers="headers"
              ></data-table-instruction-row-handler>  
            </draggable> 
          </template>
        </v-data-table>

       </v-row> 

    </v-container>

    <v-dialog
      v-model="dialog"
      width="500"
      persistent
      content-class="confirm-delete custom-class-dialog"
    >
      <v-card v-if="activeItem">
        <v-card-title>
          {{ t('confirm-delete.' + activeItem.item.type + '.title') }}
        </v-card-title>
        <v-card-text>
          {{ t('confirm-delete.' + activeItem.item.type + '.contentHtml') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="primary"
              text
              class="icon-left"
              @click="cancelDeletion()"
          >
              <v-icon>mdi-close</v-icon>
              {{ t('confirm-delete.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              @click="doDeletion()"
          >
              <v-icon>mdi-check</v-icon>
              {{ t('confirm-delete.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import { HTTP } from '@/auth'
  import toolbar from '@/components/layouts/Navigation'
  import { EventBus } from '@/event-bus'
  import DataTableInstructionRowHandler from '@/components/DataTableInstructionRowHandler'
  import draggable from 'vuedraggable'

  export default {
    name: 'InstructionsIndex',
    components: {
      toolbar,
      DataTableInstructionRowHandler,
      draggable
    },
    data() {
      return {
        activeItem: null,
        folders: [],
        currentFolder: null,
        breadcrumbs: [],
        dataLoaded: false,
        message: null,
        error: false,
        loading: false,
        selected: [],
        allowDrag: true,
        totalInstructions: 0,
        dialog: false
      }
    },
    mounted() {
      window.addEventListener('popstate', this.onBackButtonPress)
    },
    created() {
      const folderId = this.$route.params.folderId
      if (!isNaN(folderId)) {
        this.currentFolder = {'id': folderId}
      }
      this.getDataFromApi()
      EventBus.$on('row-selected', function (item) {
        this.onListItemClick(item)
      }.bind(this))
      EventBus.$on('reload', function () {
        this.reload()
      }.bind(this))
      EventBus.$on('open-delete-dialog', function (index, item) {
        this.activeItem = {
          'index': index,
          'item': item
        }
        this.dialog = true
      }.bind(this))
    },
    beforeDestroy () {
      EventBus.$off('row-selected')
      EventBus.$off('open-delete-dialog')
      EventBus.$off('reload')
      window.removeEventListener('popstate', this.onBackButtonPress)
    },
    computed: {
      headers() {
        return [
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'drag',
            width: 56
          },
          {
            text: this.t('table.type'),
            align: 'center',
            sortable: false,
            value: 'type',
            width: 56
          },
          {
            text: this.t('table.name'),
            align: 'left',
            sortable: false,
            value: 'name'
          },
          {
            text: this.t('table.analytics'),
            align: 'center',
            sortable: false,
            value: 'analytics',
            width: 120
          },
          {
            text: this.t('table.download'),
            align: 'center',
            sortable: false,
            value: 'download',
            width: 120
          },
          {
            text: this.t('table.edit'),
            align: 'center',
            sortable: false,
            value: 'edit',
            width: 120
          },
          {
            text: this.t('table.delete'),
            align: 'center',
            sortable: false,
            value: 'delete',
            width: 120
          }
        ]
      },
      hasCurrentFolder() {
        return this.currentFolder && this.currentFolder.id
      }
    },
    methods: {
      t: function (key) {
        return this.$t('instructions.list.' + key);
      },
      onBackButtonPress(event) {
        // Handle the back button press event here
        let hrefArray = window.location.href.split('/');
        let lastElement = hrefArray[hrefArray.length - 1];

        if (!isNaN(parseInt(lastElement, 10))) {
          this.currentFolder = {'id': lastElement};
        } else {
          this.currentFolder = null;
        }
        this.getDataFromApi()
      },
      getDataFromApi() {
        this.loading = true
        this.dataLoaded = false
        this.apiCall().then(data => {
          this.folders = data.data.folders
          this.folders.forEach((folder) => {
            this.$set(folder, 'isHovered', false)
          })
          this.loading = false
          this.dataLoaded = true
          this.totalInstructions = data.data.folders.length
          this.breadcrumbs = data.data.breadcrumbs
          this.currentFolder = data.data.currentFolder
        })
      },
      apiCall() {
        return new Promise((resolve, reject) => {
          let url = 'instructions/explorer'
          if (this.currentFolder != null) {
            url = 'instructions/explorer/' + this.currentFolder.id
          }
          HTTP.get(url).then(function (response) {
            let data = response.data
            resolve({
              data,
            })
          }.bind(this))
        })
      },
      downloadInstruction(item){
        HTTP.get('documents/download/' + item.document_id, {
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', item.filename)
          document.body.appendChild(link)
          link.click()
        })
      },
      reload () {
        this.$nextTick(() => {
          this.getDirectories(this.currentFolder)
        })
      },
      getDirectories (item) {
        this.dataLoaded = false

        if (item) {
          this.$router.push({ name: 'Instructions', params: { folderId: item.id } })
        } else {
          this.$router.push({ name: 'Instructions' })
        }

        this.currentFolder = item
        this.getDataFromApi()
      },
      onCloneCallback(item) {
        const cloneMe = JSON.parse(JSON.stringify(item))
        return cloneMe
      },
      onMoveCallback(evt, originalEvent) {
        const item = evt.draggedContext.element
        const itemIdx = evt.draggedContext.futureIndex
        if (item.locked) {
          return false
        }
        return true
      },
      async onDropCallback(items) {
          let url = '/instruction-sort'
          url += "?language=" + localStorage.getItem("currentLocale");

          await HTTP.post(url, items).then(function () {
            this.$root.infoNotification.showMessage(this.$t('instructions.list.sorting-sucessful'))
          }.bind(this))
      },
      onListItemClick(item) {
        this.getDirectories(item)
      },
      createNewEntry(type) {
        return {
          'name': 'Instructions' + type.charAt(0).toUpperCase() + type.slice(1) + 'Create',
          'params': { 'parent_id' : this.currentFolder ? this.currentFolder.id : null}
        }
      },
      doDeletion() {    
        let url = '';
        if (this.activeItem.item.type == 'instruction') {
          url = 'instructions/' + this.activeItem.item.id
        } else if (this.activeItem.item.type == 'folder') {
          url = 'instructions_folders/' + this.activeItem.item.id
        } else if (this.activeItem.item.type == 'document') {
          url = 'instructions_documents/' + this.activeItem.item.id
        }
        
        HTTP.delete(url).then(function () {
          this.folders.splice(this.activeItem.index, 1)
          this.activeItem = null
          this.dialog = false
        }.bind(this)).catch(function (error) {
          if (error.response.data.errors) {
            this.message = error.response.data.message
          }
        }.bind(this))
      },
      cancelDeletion() {
        this.dialog = false
        this.activeItem = null
      }
    }
  }
</script>

<style scoped lang="scss">
.sp-app.v-application .container--fluid.edit-screen {
  padding-top: 0;
}

.table-loader {
  padding: 0 !important;
  height: auto !important;
}
</style>

<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="breadcrumbs-bar">
        <v-container fluid>
          <span 
            class="breadcrumb-item"  
            @click="breadcrumbs.length === 0 ? null : openFolder(null)"
          >
            {{ t('title') }}
          </span>
          
          <span v-for="(value, index) in breadcrumbs" :key="index" 
            class="breadcrumb-item" 
            @click="index !== breadcrumbs.length - 1 ? openFolder(value) : null">
            <span :class="{ 'current-folder': index === breadcrumbs.length - 1 }" > / {{ value.title }}</span>
          </span>
        </v-container>
      </div>

      <v-container fluid>

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div>
            <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              :to="{ name: 'GalleryFolderCreate', params: { parentFolderId: curFolderId ? curFolderId : null } }"
            >
              <v-icon>mdi-folder-plus</v-icon>
              {{ t('create-folder') }}
            </v-btn>
          </div>

          <div>
            <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              :to="{ name: 'GalleryCreate', params: { parentFolderId: curFolderId ? curFolderId : null } }"
            >
              <v-icon>mdi-collections</v-icon>
              {{ t('create-gallery') }}
            </v-btn>
          </div>
        </v-row>

        <v-row>

          <v-data-table
            calculate-widths
            :loading="!dataLoaded"
            hide-default-footer
            :headers="headers"
            :items="items"
            class="elevation-0"
          >
            <template #body>
              <tbody v-if="loading">
                <tr>
                  <td colspan="6" class="table-loader">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                  </td>
                </tr>
              </tbody>
              <draggable
                v-else
                v-model="items"
                tag="tbody"
                handle=".drag-handle"
                @end="onDragged"
              >
                <tr v-for="(item, index) in items" :key="item.id">
                  <td  style="cursor: move;">
                    <v-icon class="drag-handle" color="primary">mdi-drag</v-icon>
                  </td>

                  <td 
                    @click="open(item)"
                    @mouseover="item.isHovered = true"
                    @mouseleave="item.isHovered = false"
                    :class="[{ 'open-folder': item.type === 'Folder' }]"
                  >
                    <v-icon v-if="item.type === 'Folder' && !item.isHovered" color="primary">mdi-folder</v-icon>
                    <v-icon v-if="item.type === 'Folder' && item.isHovered" color="primary">mdi-folder-open</v-icon>
                    <v-icon v-if="item.type === 'Gallery'" color="primary">mdi-image-multiple</v-icon>
                  </td>

                  <td
                    @click="open(item)"
                    @mouseover="item.isHovered = true"
                    @mouseleave="item.isHovered = false"
                    :class="[{ 'open-folder': item.type === 'Folder' }]"
                  >
                    {{ item.title }}
                  </td>

                  <td align="center">
                    {{ item.photoCount }}
                  </td>

                  <td align="center">
                    <v-btn text @click="edit(item)">
                      <v-icon small class="edit-row-button" color="primary">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>

        </v-row>
      </v-container>

    </div>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import draggable from 'vuedraggable'

export default {
  name: 'GalleryIndex',
  components: {
    toolbar,
    loading,
    draggable,
  },
  data() {
    return {
      items: [],
      breadcrumbs: [],
      loading: false,
      dataLoaded: false,
      curFolderId: null
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'drag',
          width: 30
        },
        { 
          text: this.t('type'), 
          value: 'type', 
          align: 'center',
          sortable: false,
          width: 55
        },
        { 
          text: this.t('name'), 
          value: 'title', 
          align: 'left',
          sortable: false
        },
        { 
          text: this.t('amount-images'), 
          value: 'photoCount', 
          align: 'center', 
          width: 150,
          sortable: false
        },
        { 
          text: this.t('edit'), 
          value: 'edit', 
          align: 'center', 
          width: 120,
          sortable: false
        }
      ]
    }
  },
  mounted() {
    window.addEventListener('popstate', this.onBackButtonPress)
  },
  async created() {
    if (this.$route.params.folderId) {
      this.curFolderId = this.$route.params.folderId
    }
    await this.loadData()
  },
  beforeDestroy () {
    window.removeEventListener('popstate', this.onBackButtonPress)
  },
  methods: {
    t: function (key) {
      return this.$t('galleries.list.' + key)
    },
    onBackButtonPress(event) {
      // Handle the back button press event here
      let hrefArray = window.location.href.split('/')
      let lastElement = hrefArray[hrefArray.length - 1]

      if (!isNaN(parseInt(lastElement, 10))) {
        this.curFolderId = lastElement
      } else {
        this.curFolderId = null
      }
      this.loadData()
    },
    async loadData() {
      this.loading = true
      let idText = this.curFolderId ? '/' + this.curFolderId : ''
      try {
        const response = await HTTP.get('galleries' + idText)
        this.items = response.data.items
        this.items.forEach((item) => {
          this.$set(item, 'isHovered', false)
        })
        this.breadcrumbs = response.data.breadcrumbs.splice(1, response.data.breadcrumbs.length - 1)
        this.dataLoaded = true
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async open(item) {
      if (item.type === 'Folder') {
        await this.openFolder(item)
      }
    },
    async openFolder(item) {
      if (!item || !item.id) {
        this.curFolderId = null
        await this.$router.push({ name: `GalleryIndex` })
      } else {
        this.curFolderId = item.id;
        await this.$router.push({ name: 'GalleryIndex', params: { folderId: this.curFolderId } })
      }
      await this.loadData()
    },
    async edit(item) {
      if (item && item.id) {
        const name = item.type === 'Gallery' ? 'GalleryEdit' : 'GalleryFolderEdit'
        await this.$router.push({ name: name, params: { id: item.id } })
      }
    },
    async onDragged(event) {
      if (!Array.isArray(this.items)) {
        console.error("Fehler: 'items' ist kein Array", this.items)
        return;
      }

      let newSort = this.items.map(item => item.id)
      let idText = this.curFolderId ? this.curFolderId : ''

      try {
        await HTTP.post(`galleries/set-order/${idText}`, { sort: newSort })
      } catch (error) {
        console.error("Fehler beim Speichern der neuen Reihenfolge:", error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sp-app.v-application .has-hero-container {
  padding-top: 0 !important;
}

.table-loader {
  padding: 0 !important;
  height: auto !important;
}

.open-folder {
  cursor: pointer;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('basic-edit-container',{attrs:{"title":_vm.t('document.title-edit'),"has-changes":_vm.hasChanges,"data-loaded":_vm.dataLoaded,"delete-action":_vm.deleteItem,"save-action":_vm.save},on:{"saved":_vm.onSaved,"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('document.group-title')))]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.t('document.document-name'),"counter":"120","filled":"","rules":[
                function () { return !!_vm.item.title || _vm.$t('validation.required.default'); },
                function () { return (_vm.item.title && _vm.item.title.length <= 120) || _vm.$t('validation.length.default'); }
              ]},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}}),_c('v-text-field',{attrs:{"label":_vm.t('document.description'),"counter":"120","filled":"","rules":[
								function () { return (!_vm.item.description || (_vm.item.description && _vm.item.description.length <= 120)) || _vm.$t('validation.length.description'); }
							]},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}}),(_vm.item.url)?_c('div',{staticClass:"existing-file"},[_c('v-icon',[_vm._v(" mdi-paperclip ")]),_c('span',[_vm._v(_vm._s(_vm.item.filename)+" ( "+_vm._s(_vm.item.filesize)+"kb )")]),_c('v-icon',{on:{"click":function($event){_vm.item.url = ''}}},[_vm._v(" delete ")])],1):_c('v-file-input',{attrs:{"label":_vm.t('document.choose-file'),"truncate-length":"40","show-size":"","filled":"","rules":[
                function () { return !!_vm.document || _vm.$t('validation.required.default'); }
              ]},model:{value:(_vm.document),callback:function ($$v) {_vm.document=$$v},expression:"document"}}),_c('v-text-field',{staticClass:"has-hint-message",attrs:{"label":_vm.t('document.keywords'),"filled":"","hint":_vm.t('document.keywords-hint'),"hide-details":""},model:{value:(_vm.item.keywords),callback:function ($$v) {_vm.$set(_vm.item, "keywords", $$v)},expression:"item.keywords"}})],1)],1)]},proxy:true},{key:"meta",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('components.permissiongroup-select.label')))]),_c('v-card-text',[_c('permission-group-selector',{attrs:{"required":true,"mode":"dropdown"},model:{value:(_vm.item.assigned_permission_groups),callback:function ($$v) {_vm.$set(_vm.item, "assigned_permission_groups", $$v)},expression:"item.assigned_permission_groups"}})],1)],1),_c('v-card',{staticClass:"sp-mar-top-3",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('move-document.group-title')))]),_c('v-card-text',[_c('div',[_c('v-btn',{staticClass:"icon-left",attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){_vm.dialogVisible = true}}},[_c('v-icon',[_vm._v("mdi-folder-search-outline")]),_vm._v(" "+_vm._s(_vm.t('move-document.choose-folder'))+" ")],1),(_vm.moveToDir)?_c('p',{staticClass:"sp-mar-top-2"},[_vm._v(_vm._s(_vm.t('move-document.will-be-moved'))+": "),_c('b',[_vm._v(_vm._s(_vm.moveToDir.name))])]):_vm._e()],1)])],1)]},proxy:true}])}),(_vm.dataLoaded)?_c('MoveToFolderDialog',{attrs:{"dialog":_vm.dialogVisible,"root-name":_vm.$t('documents.list.title'),"parent-folder-id":_vm.item.parent_folder_id,"get-url":"documents/folders/all"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
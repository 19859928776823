<template>
  <div>
    <basic-edit-container
      :title="t('edit.title')"
      :data-loaded="!loading"
      ref="editor"
      @cancel="close"
      :saveAction="save"
      @saved="onSaved"
      :delete-action="doDelete"
      @deleted="onDeleted"
      :has-changes="hasChanges"
    >
      <v-card elevation="0">
        <v-card-title>{{ t('details.group-title') }}</v-card-title>
        <v-card-text>
          <v-text-field
            :label="t('details.name')"
            v-model="data.title"
            counter="120"
            filled
            :rules="[
              () => !!data.title || $t('validation.required.default'),
              () => (data.title && data.title.length <= 120) || $t('validation.length.default')
            ]"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </basic-edit-container>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'

export default {
  name: 'GalleryFolderEdit',
  components: {
    BasicEditContainer
  },
  data() {
    return {
      galleryId: null,
      loading: true,
      data: {
        title: ''
      },
      dataOriginal: null
    }
  },
  async created() {
    this.galleryId = this.$route.params.id
    await this.loadData()
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    },
  },
  methods: {
    t: function (key) {
      return this.$t('galleries.folder.detail.' + key)
    },
    async loadData() {
      this.loading = true
      try {
        let response = await HTTP.get('galleries/folder/' + this.galleryId)
        this.data = response.data
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
      } catch (e) {
        this.$root.infoNotification.showResponseError(e)
      } finally {
        this.loading = false
      }
    },
    save() {
      return HTTP.put('galleries/folder/' + this.galleryId, this.data)
    },
    async onSaved() {
      this.dataOriginal = null
      this.close()
    },
    close() {
      this.$router.back()
    },
    doDelete() {
      return HTTP.delete('galleries/folder/' + this.galleryId)
    },
    async onDeleted() {
      this.close()
    }
  }
}
</script>

<template>
    <div class="d-flex flex-column">
        <div class="choose-icon-wrapper" :class="{ compact }">
            <div class="icon-preview-box">
                <div class="icon-label">
                    <b>{{ $t('components.choose-icon.actual_icon') }}</b>
                </div>
                <v-icon>{{ activeIcon || 'mdi-help-circle-outline' }}</v-icon>
            </div>
        </div>

        <v-btn
            elevation="0"
            @click="dialog = true"
            color="primary"
            class="icon-left ml-auto"
        >
            <v-icon>mdi-magnify</v-icon>
            {{ $t('components.choose-icon-search') }}
        </v-btn>

        <v-dialog
            v-model="dialog"
            content-class="dialog"
            width="1000"
        >
            <v-card>
                <v-card-title>
                    {{ $t('components.menu-create-new-dialog.choose-icon') }}
                </v-card-title>

                <v-card-text>
                    <div style="display: flex;">
                        <v-text-field
                            :label="$t('components.menu-create-new-dialog.short')"
                            filled
                            v-model="activeIcon"
                        ></v-text-field>

                        <v-btn
                            :disabled="activeIcon != null && activeIcon.length === 0"
                            class="show-preview"
                        >
                            <v-icon>{{ activeIcon }}</v-icon>
                        </v-btn>
                    </div>

                    {{ $t('components.menu-create-new-dialog.more_icons') }}
                    <a target="_blank" href="https://pictogrammers.github.io/@mdi/font/6.6.96/">Link</a>
                    <br>
                    
                    <br>
                    {{ $t('components.menu-create-new-dialog.library') }}
                    <div class="icons">
                        <v-icon @click="selectIcon(icon)" class="icon" v-for="(icon) in icons">{{ icon }}</v-icon>
                    </div> 
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        elevation="0"
                        @click="hideDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                        {{ $t('components.menu-create-new-dialog.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "ChooseIcon",
    model: {
      prop: 'model',
      event: 'input'
    },
    props: {
      model: {
          default: () => [null]
      },
      compact: false
    },
    methods: {
        t: function (key) {
            return this.$t(key)
        },
        getDynamicPath(type) {
            return {
                name: 'MenuCreate',
                params: { type }
            }
        },
        selectIcon(icon) {
            this.activeIcon = icon
            this.$emit('input', icon)
            this.hideDialog()
        },
        hideDialog() {
            this.dialog = false
        }
    },
    data() {
        return {
            dialog: false,
            activeIcon: this.model,
            icons: [
                "mdi-file-chart-check",
                "mdi-lightbulb-alert",
                "mdi-home-clock",
                "mdi-file-multiple",
                "mdi-card-account-details",
                "mdi-account-box",
                "mdi-account-circle",
                "mdi-cards-heart",
                "mdi-account-group",
                "mdi-account-multiple",
                "mdi-account-school",
                "mdi-airplane",
                "mdi-alert-circle",
                "mdi-alert",
                "mdi-alert-box",
                "mdi-ambulance",
                "mdi-archive",
                "mdi-badge-account-horizontal",
                "mdi-bell",
                "mdi-book",
                "mdi-book-open-blank-variant",
                "mdi-bookmark",
                "mdi-briefcase",
                "mdi-briefcase-variant",
                "mdi-bullhorn-variant",
                "mdi-bus",
                "mdi-cake-variant",
                "mdi-calendar-account",
                "mdi-calendar",
                "mdi-calendar-clock",
                "mdi-calendar-remove",
                "mdi-file-image",
                "mdi-file-document-multiple",
                "mdi-file-chart",
                "mdi-emoticon-wink",
                "mdi-emoticon-excited",
                "mdi-emoticon",
                "mdi-email-search",
                "mdi-email",
                "mdi-download-box",
                "mdi-contacts",
                "mdi-comment-text",
                "mdi-comment-question",
                "mdi-comment-check",
                "mdi-comment",
                "mdi-comment-account",
                "mdi-coffee",
                "mdi-close-box",
                "mdi-clock-time-four",
                "mdi-clock-time-seven",
                "mdi-clock-time-eight",
                "mdi-clipboard-text-search",
                "mdi-clipboard-pulse",
                "mdi-clipboard-list",
                "mdi-clipboard-edit",
                "mdi-chart-areaspline-variant",
                "mdi-chart-pie",
                "mdi-chart-bar",
                "mdi-chart-box",
                "mdi-cart",
                "mdi-file-multiple",
                "mdi-lightbulb-alert",
                "mdi-flag",
                "mdi-flash",
                "mdi-flower",
                "mdi-folder",
                "mdi-folder-open",
                "mdi-forum",
                "mdi-gift",
                "mdi-glass-fragile",
                "mdi-hand-heart",
                "mdi-hand-saw",
                "mdi-help-box",
                "mdi-home-alert",
                "mdi-human-male-female-child",
                "mdi-image",
                "mdi-lightbulb",
                "mdi-lightbulb-on",
                "mdi-lock",
                "mdi-mailbox",
                "mdi-seal",
                "mdi-rocket-launch",
                "mdi-puzzle",
                "mdi-phone-log",
                "mdi-phone-classic",
                "mdi-phone",
                "mdi-note-text",
                "mdi-note",
                "mdi-message-question",
                "mdi-message",
                "mdi-sticker-alert",
                "mdi-store-remove",
                "mdi-timeline-remove",
                "mdi-truck",
                "mdi-truck-remove",
                "mdi-truck-plus",
                "mdi-tshirt-crew",
                "mdi-umbrella",
                "mdi-view-dashboard"
            ]
        }
    },
    watch: {
        model: function(val) {
            this.activeIcon = val
        },
        activeIcon: function(val) {
            this.$emit('input', val)
        }
    }
}
</script>

<style scoped lang="scss">
.choose-icon-block {
    display: flex;
    margin: 32px 0;

    &.compact {
        margin: 0 0 16px 0; 
    }

    & > div {
        line-height: 36px;
        margin-right: 16px;
    }

    .v-btn {
        margin-left: auto;
    }
}

.menu-create-title {
    font-size: 14px;
    font-weight: 600;
}

.icons {
    margin-top: 15px;
}

.icon {
    margin-right: 15px;
    cursor: pointer;
}

.choose-icon-button {
    float: right;
}

.v-text-field__details {
    display: none !important;
}

.show-preview {
    width: 57px;
    height: 57px !important;
    margin-left: 15px;
    margin-bottom: 10px;
    box-shadow: none !important;
}

.choose-icon-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 0 16px 0;
}

.icon-preview-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F5F5F5;
  padding: 16px;
  border-radius: 4px;
}

.icon-label {
  font-size: 16px;
  font-weight: 400;
  color: #062e21;
}
</style>


import ExpensesIndex from '@/views/ExpenseIndex'
import ExpenseEdit from '@/views/ExpenseEdit'
import SettingsExpenses from '@/views/SettingsExpenses'

const expensesRoutes = [
  {
    path: '/expenses',
    name: 'Expenses',
    component: ExpensesIndex,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true,
        requiredSettingsPermission: ['expense_settings_email', 'expense_type_manage']
      }
    }
  },
  {
    path: '/expenses/:id?',
    name: 'Expense',
    component: ExpenseEdit,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true,
        requiredSettingsPermission: ['expense_settings_email', 'expense_type_manage']
      }
    }
  },
  {
    path: '/settings/expense',
    name: 'SettingsExpenses',
    component: SettingsExpenses,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true,
        requiredSettingsPermission: ['expense_settings_email', 'expense_type_manage']
      }
    }
  }
]

export default expensesRoutes

<template>
        <tr :class="getClass">
            <td v-for="(header, index) in headers" :key="index" :style="[{'width': header.width + 'px'}, { 'cursor': header.value == 'edit' ? 'default' : 'grab'}]" :class="[{ 'pr-0': header.value == 'drag' }]">
                <slot :item="item" :name="columnName(header)">
                    <div v-if="header.value == 'drag'" :style="getAlignment(header)">
                        <v-icon class="drag-handle">mdi-drag</v-icon>
                    </div>
                
                    <div v-if="header.value == 'name'" :style="getAlignment(header)">
                        <span>{{ item.name }}</span>
                    </div>
            
                    <div v-if="header.value == 'status'" :style="getAlignment(header)">
                        <status-icon-widget :status="item.is_active ? 'active' : 'inactive'" :hide-text="false"/>
                    </div>
            
                    <div v-if="header.value == 'edit'" :style="getAlignment(header)">
                        <v-icon
                            small
                            class="edit-row-button"
                            @click="openSettingsDialog(rowIndex, item)"
                        >
                            edit
                        </v-icon>
                    </div>
                </slot>
            </td>
        </tr>
</template>
  
<script>
  import { EventBus } from '@/event-bus'
  import StatusIconWidget from '@/components/layouts/StatusIconWidget'
  
  export default {
    name: "DataTableExpenseTypesRowHandler",
    components: {
      StatusIconWidget
    },
    props: {
      itemClass: {
        type: String,
        default: "",
      },
      rowIndex: {
        type: Number,
        default: 0,
      },
      item: {
        type: Object,
        default: () => {
          return {}
        },
      },
      headers: {
        type: Array,
        default: () => {
          return []
        },
      }
    },
    data() {
      return {}
    },
    computed: {
      getClass() {
        return this.itemClass
      }
    },
    methods: {
      columnName(header) {
        return `item.${header.value}`
      },
      getAlignment(header) {
        const align = header.align ? header.align : "center"
        return `text-align: ${align}`
      },
      openSettingsDialog(index, item) {
        EventBus.$emit('et-open-settings-dialog', index, item)
      }
    }
  }
</script>
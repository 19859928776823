var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasicNewContainer',{ref:"editor",attrs:{"title":_vm.t('document.title-create'),"saveAction":_vm.submit,"has-changes":_vm.hasChanges},on:{"cancel":_vm.close,"saved":_vm.onSaved},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('document.group-title')))]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.t('document.document-name'),"counter":"120","filled":"","rules":[
              function () { return !!_vm.data.title || _vm.$t('validation.required.default'); },
              function () { return (_vm.data.title && _vm.data.title.length <= 120) || _vm.$t('validation.length.default'); }
            ]},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),_c('v-text-field',{attrs:{"label":_vm.t('document.description'),"counter":"120","filled":"","rules":[
              function () { return _vm.data.description.length <= 120 || _vm.$t('validation.length.description'); }
            ]},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}}),_c('v-file-input',{attrs:{"label":_vm.t('document.choose-file'),"truncate-length":"40","show-size":"","filled":"","rules":[
              function () { return !!_vm.inputDocumentFile || _vm.$t('validation.required.default'); }
            ]},model:{value:(_vm.inputDocumentFile),callback:function ($$v) {_vm.inputDocumentFile=$$v},expression:"inputDocumentFile"}}),_c('v-text-field',{staticClass:"has-hint-message",attrs:{"label":_vm.t('document.keywords'),"filled":"","hint":_vm.t('document.keywords-hint')},model:{value:(_vm.data.keywords),callback:function ($$v) {_vm.$set(_vm.data, "keywords", $$v)},expression:"data.keywords"}})],1)],1)]},proxy:true},{key:"meta",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('components.permissiongroup-select.label')))]),_c('v-card-text',[_c('permission-group-selector',{attrs:{"required":true,"mode":"dropdown"},model:{value:(_vm.data.permission_groups),callback:function ($$v) {_vm.$set(_vm.data, "permission_groups", $$v)},expression:"data.permission_groups"}})],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
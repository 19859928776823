<template>
    <div>
        <v-list v-if="mode==='list'">
            <v-list-item-group v-model="selectedGroups" multiple>
                <template v-for="(item, i) in groups" >
                    <v-list-item :value="item.id">
                        <template v-slot:default="{ active }">
                            <v-list-item-content :key="i">
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-checkbox :input-value="active"/>
                            </v-list-item-action>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
        <v-select v-if="mode==='dropdown'"
            filled
            :title="$t('components.permissiongroup-select.select')"
            :label="$t('components.permissiongroup-select.select')"
            v-model="selectedGroups"
            multiple
            :items="groups"
            item-text="name"
            item-value="id"
            @blur="selectBlur"
            :data-cy="dataCy"
            :rules="[() => {
                if (required && selectedGroups.length === 0) {
                    return $t('validation.required.default');
                }
                return true;
            }]"
            hide-details="auto"
        >
            <template slot="item" slot-scope="data">
                {{ data.item.name }} ({{ data.item.user_count }})
            </template>
        </v-select>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus'

export default {
    name: "PermissionGroupSelector",
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
      dataCy: {
        default: ''
      },
        mode: {
            type: String,
            default: 'list',
        },
        value: {
            default: null,
        },
        required: {
            type: Boolean,
            default: false
        },
        model: {
            type: Array,
            default: () => [null]
        },
    },
    data() {
        return {
            selectedGroups: this.model,
            groups: []
        }
    },
    watch: {
        selectedGroups: function (val) {
            this.$emit('input', val)
        },
        model: function(val) {
            this.selectedGroups = this.model
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        selectBlur() {
            EventBus.$emit('pgs-selector.blur')
        },
        async loadData() {
            let response = await HTTP.get('permission-groups?sortBy[]=name')
            this.groups = response.data.data
        }
    },
}
</script>

<template>
  <div class="expense-type-filter">
    <div>
      <v-select
        v-model="selectedFilterTypes"
        :label="$t('components.content-filter-expense-types.label')"
        :items="filterTypes"
        :item-text="getDisplayName"
        item-value="id"
        multiple
        chips
      ></v-select>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/auth'

export default {
  name: "ExpenseTypeFilter",
  props: ['value'],
  data() {
    return {
      selectedFilterTypes: this.value,
      filterTypes: []
    }
  },
  watch: {
    selectedFilterTypes: function (val) {
      this.$emit('input', val)
    },
    value: function (val) {
      this.selectedFilterTypes = val
    }
  },
  async created() {
    let response = await HTTP.get('expense-types')
    this.filterTypes = response.data
  },
  methods: {
    getDisplayName(item) {
      if (item.name) {
        return item.name
      }
      return this.$t('expenses.data.name_codes.' + item.name_code)
    },
  },
}
</script>

<style scoped>
.expense-type-filter {
  min-width: 320px;
}
</style>

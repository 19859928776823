import GalleryIndex from '@/views/GalleryIndex'
import GalleryCreate from '@/views/GalleryCreate'
import GalleryEdit from '@/views/GalleryEdit'
import GalleryFolderCreate from '@/views/GalleryFolderCreate'
import GalleryFolderEdit from '@/views/GalleryFolderEdit'

const galleriesRoutes = [
    {
        path: '/galleries/create-gallery/:parentFolderId?',
        name: 'GalleryCreate',
        component: GalleryCreate,
        meta: {
            accessConfig:  {
              moduleName: ['gallery'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/galleries/create-folder/:parentFolderId?',
        name: 'GalleryFolderCreate',
        component: GalleryFolderCreate,
        meta: {
            accessConfig:  {
              moduleName: ['gallery'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/galleries/gallery-edit/:id?',
        name: 'GalleryEdit',
        component: GalleryEdit,
        meta: {
            accessConfig:  {
              moduleName: ['gallery'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/galleries/folder-edit/:id?',
        name: 'GalleryFolderEdit',
        component: GalleryFolderEdit,
        meta: {
            accessConfig:  {
              moduleName: ['gallery'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/galleries/:folderId?',
        name: 'GalleryIndex',
        component: GalleryIndex,
        meta: {
            accessConfig:  {
              moduleName: ['gallery'],
              requiresAuth: true
            }
        }
    }
]

export default galleriesRoutes

<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <div class="breadcrumbs-bar">
      <v-container fluid>
        <span 
          class="breadcrumb-item"  
          @click="breadcrumbs.length === 0 ? null : openFolder(null)"
        >
          {{ t('title') }}
        </span>
        
        <span v-for="(value, index) in breadcrumbs" :key="index" 
          class="breadcrumb-item" 
          @click="index !== breadcrumbs.length - 1 ? openFolder(value) : null">
          <span :class="{ 'current-folder': index === breadcrumbs.length - 1 }" > / {{ value.name }}</span>
        </span>
      </v-container>
    </div>

    <v-container fluid>
      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="{ name: 'DocumentsCreateFolder', params: { parentFolderId: curFolderId ? curFolderId : null } }"
          >
            <v-icon>mdi-folder-plus</v-icon>
            {{ t('create-folder') }}
          </v-btn>
        </div>

        <div>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="{ name: 'DocumentsCreateDocument', params: { parentFolderId: curFolderId ? curFolderId : null } }"
          >
            <v-icon>mdi-file-upload-outline</v-icon>
            {{ t('upload-file') }}
          </v-btn>
        </div>
      </v-row>

      <v-row>

        <v-data-table :headers="headers" class="elevation-0" hide-default-footer>
          <template #body>
            <tbody v-if="!dataLoaded">
              <tr>
                <td colspan="6" class="table-loader">
                  <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </td>
              </tr>
            </tbody>
            <draggable v-else v-model="folders" @end="updateItemOrder" tag="tbody" handle=".drag-handle">
              <tr v-for="(item, index) in folders" :key="item.id">
                <td style="cursor: move;">
                  <v-icon class="drag-handle" color="primary">mdi-drag</v-icon>
                </td>

                <td 
                  @click="open(item)"
                  @mouseover="item.isHovered = true"
                  @mouseleave="item.isHovered = false"
                  :class="[{ 'open-folder': item.folder_id }]"
                >
                  <v-icon v-if="item.folder_id && !item.isHovered" color="primary">mdi-folder</v-icon>
                  <v-icon v-else-if="item.folder_id && item.isHovered" color="primary">mdi-folder-open</v-icon>
                  <v-icon v-else-if="item.mimetype.match(/pdf/)" color="primary">mdi-file-pdf-box</v-icon>
                  <v-icon v-else-if="item.mimetype.match(/image/)" color="primary">mdi-file-image</v-icon>
                  <v-icon v-else-if="item.mimetype.match(/video/)" color="primary">mdi-file-video</v-icon>
                  <v-icon v-else color="primary">mdi-file</v-icon>
                </td>

                <td
                  @click="open(item)"
                  @mouseover="item.isHovered = true"
                  @mouseleave="item.isHovered = false"
                  :class="[{ 'open-folder': item.folder_id }]"
                >
                  {{ item.folder_id ? item.name : item.title }}
                </td> 

                <td align="center">  
                  <v-btn 
                    v-if="!item.folder_id"
                    text
                    @click="downloadFile(item)"
                  >
                    <v-icon color="primary">mdi-download</v-icon>
                  </v-btn>
                </td>

                <td align="center">
                  <v-btn 
                    v-if="item.folder_id"
                    text
                    @click="editFolder(item.folder_id)"
                  >
                    <v-icon color="primary">mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn 
                    v-else
                    text
                    @click="ediDocument(item)"
                  >
                    <v-icon color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </td>

                <td align="center">
                  <v-btn 
                    text
                    @click="openDeleteDialog(item)"
                  >
                    <v-icon color="primary">mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>

      </v-row>
    </v-container>

    <!-- Confirm dialog to delete folder and files in it -->
    <md-dialog-confirm
      :md-title="t('confirm-delete.folder.title')"
      :md-content-html="t('confirm-delete.folder.contentHtml')"
      :md-ok-text="t('confirm-delete.ok')"
      :md-cancel-text="t('confirm-delete.cancel')"
      @close="onConfirmClose"
      ref="confirm-delete-folder"
      :class="['confirm-delete']">
    </md-dialog-confirm>

    <!-- Confirm dialog to delete document-->
    <md-dialog-confirm
      :md-title="t('confirm-delete.document.title')"
      :md-content-html="t('confirm-delete.document.contentHtml')"
      :md-ok-text="t('confirm-delete.ok')"
      :md-cancel-text="t('confirm-delete.cancel')"
      @close="onConfirmClose"
      ref="confirm-delete-file"
      :class="['confirm-delete']">
    </md-dialog-confirm>

    <v-snackbar
      ref="snackbar"
      v-model="snackbar"
      timeout="4000"
    >
      {{ message }}
    </v-snackbar>

  </div>
</template>

<script>
  import { HTTP } from '@/auth'
  import toolbar from '@/components/layouts/Navigation'
  import loading from '@/components/layouts/Loading'
  import draggable from 'vuedraggable'

  export default {
    name: 'DocumentsIndex',
    components: {
      toolbar,
      loading,
      draggable
    },
    data () {
      return {
        activeItem: null,
        activeItemIndex: null,
        folders: null,
        currentFolder: null,
        curFolderId: null,
        breadcrumbs: [],
        newFolderName: null,
        renameFolderName: null,
        timer: null,
        timerDragDrop: null,
        results: 0,
        dataLoaded: false,
        showEditData: false,
        showCreateData: false,
        showEditFolder: false,
        showCreateFolder: false,
        message: null,
        error: false,
        cancelHTTP: null,
        errorCancel: null,
        confirm: {
          folder: {
            title: 'Ordner löschen',
            contentHtml: 'Möchten Sie den ausgewählten Ordner und dessen Inhalt wirklich löschen?'
          },
          document: {
            title: 'Dokument löschen',
            contentHtml: 'Möchten Sie das ausgewählte Dokument wirklich löschen?'
          },
          ok: 'Löschen',
          cancel: 'Abbrechen'
        },
		    snackbar: false,
        loading: false
      }
    },
    mounted() {
      window.addEventListener('popstate', this.onBackButtonPress)
    },
    created () {
      if (this.$route.params.folderId) {
        this.curFolderId = this.$route.params.folderId
      }
      this.getDirectories(this.curFolderId)
    },
    beforeDestroy () {
      window.removeEventListener('popstate', this.onBackButtonPress)
    },
    computed: {
      headers() {
        return [
          { 
            text: "",
            value: "drag",
            sortable: false,
            align: "center",
            width: 55
          }, 
          { 
            text: this.t("table.type"),
            value: "type",
            sortable: false,
            align: "center",
            width: 50
          },
          {
            text: this.t("table.name"),
            value: "name",
            sortable: false
          },
          { 
            text: this.t("table.download"),
            value: "download",
            sortable: false,
            width: 120
          },
          { 
            text: this.t("table.edit"),
            value: "edit",
            sortable: false,
            width: 120
          },
          { 
            text: this.t("table.delete"),
            value: "delete",
            sortable: false,
            width: 120
          }
        ]
      }
    },
    methods: {
      t: function (key) {
        return this.$t('documents.list.' + key)
      },
      onBackButtonPress(event) {
        // Handle the back button press event here
        let hrefArray = window.location.href.split('/')
        let lastElement = hrefArray[hrefArray.length - 1]

        if (!isNaN(parseInt(lastElement, 10))) {
          this.curFolderId = lastElement
        } else {
          this.curFolderId = null
        }
        this.getDirectories(this.curFolderId)
      },
      getDirectories(folderId) {
        this.dataLoaded = false

        let url = 'documents/explorer'
        if (folderId) {
          url = 'documents/explorer/' + folderId
        }

        HTTP.get(url).then(function (response) {
          this.currentFolder = response.data.folders
          this.curFolderId = this.currentFolder.id
          this.folders = response.data.folders.children
          this.folders.forEach((folder) => {
            this.$set(folder, 'isHovered', false)
          })
          this.results = this.folders.filter(x => !x.is_directory).length
          this.breadcrumbs = response.data.breadcrumbs
          this.error = false
          this.dataLoaded = true
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
          }
        }.bind(this))
      },
      saveOrder() {
        let data = {
          'items': this.folders
        }
        HTTP.post('documents/folders/order',  data)
          .then(function () {
            this.getDirectories(this.currentFolder.id)
            this.$root.infoNotification.showMessage(this.t('order-saved-successfully'))
          }.bind(this)).catch(function (error) {
            console.log(error.response)
          }.bind(this))
      },
      updateItemOrder() {
        if (this.folders.length > 1) {
          clearTimeout(this.timerDragDrop)

          this.timerDragDrop = setTimeout(() => {
            this.saveOrder()
          }, 1000)
        }
      },
      open(item) {
        if (item.folder_id) {
          this.openFolder(item)
        }
      },
      openFolder(item) {
        if (!item || !item.folder_id) {
          this.curFolderId = null
          this.$router.push({ name: `DocumentsIndex` })
        } else {
          this.curFolderId = item.folder_id
          this.$router.push({ name: 'DocumentsIndex', params: { folderId: item.folder_id } })
        }
        this.getDirectories(this.curFolderId)
      },
      downloadFile(item){
        HTTP.get('documents/download/' + item.document_id, {
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', item.filename)
          document.body.appendChild(link)
          link.click()
        })
      },
      editFolder(folderId) {
        this.$router.push({ name: 'DocumentsEditFolder', params: { id: folderId } })
      },
      ediDocument(item) {
        this.$router.push({ name: 'DocumentsEditDocument', params: { id: item.document_id } })
      },
      onConfirmClose (type) {
        if (type === 'ok') {
          if(this.activeItem.folder_id) {
            this.deleteItem(this.activeItem.folder_id, 'folder')
          } else {
            this.deleteItem(this.activeItem.document_id, 'document')
          }
        } else {
          this.activeItem = null
        }
      },
      openSnackbar () {
        setTimeout(() => {
          this.snackbar = true
        }, 2000)
      },
      openDeleteDialog(item) {
        if (!item) {
          return
        }

        this.activeItem = item
        if (item.folder_id) {
          this.$refs["confirm-delete-folder"].open()
        } else if (item.document_id) {
          this.$refs["confirm-delete-file"].open()
        } else {
          console.error("Fehler: Weder folder_id noch document_id vorhanden.");
        }
      },
      deleteItem(itemId, type) {
        let url = 'documents/' + itemId
        let message = this.$t('documents.list.element-successfully-deleted', { title: this.activeItem.title })

        if (type === 'folder') {
          url = 'documents/folders/' + itemId
          message = this.$t('documents.list.element-successfully-deleted', { title: this.activeItem.name })
        }

        HTTP.delete(url).then(function () {
            this.getDirectories(this.currentFolder.id)
            this.activeItem = null
            this.$root.infoNotification.showMessage(message)
          }.bind(this)).catch(function (error) {
            console.log(error.response)
          }.bind(this))
      }
    }
  }
</script>

<style scoped lang="scss">
.sp-app.v-application .container--fluid.edit-screen {
  padding-top: 0;
}

.table-loader {
  padding: 0 !important;
  height: auto !important;
}

.open-folder {
  cursor: pointer;
}
</style>

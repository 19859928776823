<template>
  <div>
    <BasicEditContainer
      ref="editor"
      :data-loaded="dataLoaded"
      v-if="dataLoaded"
      :title="t('title')"
      @cancel="cancel"
      :allow-delete="false"
      :object-type="$t('expenses.data.object-type')"
      :object-name="item.title"
      :has-changes="false"
    >
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              :label="t('details.title')"
              filled
              :value="item.title"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              :label="t('details.description')"
              filled
              :value="item.description"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              :label="t('details.expense-type')"
              filled
              :value="item.name && item.name !== '' ? item.name : $t('expenses.data.type.' + item.name_code)"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              :label="t('details.date')"
              filled
              :value="formatDate(item.date)"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              v-if="item.type.calculation_type_code === 'AT_COST'"
              class="mt-4"
              :label="t('details.amount_incl_vat')"
              filled
              :value="item.total_amount_incl_vat + ' ' + item.currency"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              v-if="item.type.calculation_type_code === 'HOURLY_RATE'"
              class="mt-4"
              :label="t('details.hours')"
              filled
              :value="item.hours + ' ' + t('hours')"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              v-if="item.type.calculation_type_code === 'FACTOR'"
              class="mt-4"
              :label="t('details.factor')"
              filled
              :value="item.quantity + ' ' + item.unit"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              :label="t('details.employee')"
              filled
              :value="item.user.display_name"
              :disabled="true"
              hide-details
            ></v-text-field>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('comment.group-title') }}</v-card-title>
          <v-card-text>
            <v-textarea
              v-if="!hasApprovalStatus"
              v-model="approvalComment"
              solo
              name="comment"
              :label="t('comment.hint')"
              hide-details="auto"
            ></v-textarea>

            <span v-if="hasApprovalStatus">
              {{ item.approval_comment }}
            </span>
          </v-card-text>
        </v-card>

        <v-card v-if="item.is_reimbursed && item.reimbursement_amount" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('reimburse.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              :label="t('reimburse.amount')"
              filled
              :value="item.reimbursement_amount + ' ' + item.reimbursement_currency"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              v-if="item.status === 'reimbursed'"
              class="mt-4"
              :label="t('reimburse.date')"
              filled
              :value="formatDate(item.reimbursement_date)"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              v-if="item.status === 'reimbursed'"
              class="mt-4"
              :label="t('reimburse.comment')"
              filled
              :value="item.reimbursement_comment"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              v-if="item.status === 'reimbursed'"
              class="mt-4"
              :label="t('reimburse.approved_by')"
              filled
              :value="item.reimbursement_user.displayName"
              :disabled="true"
              hide-details
            ></v-text-field>
          </v-card-text>
        </v-card>
      </template>
      
      <template v-slot:meta>
        <v-card elevation="0" v-if="item.documents" class="sp-mar-bot-3">
          <v-card-title>{{ t('picture.group-title') }}</v-card-title>
          <v-card-text class="expense-image-wrapper">
            <div v-for="(image, index) in imageData" :key="index" class="expense-image">
              <v-img :src="image"></v-img>
              <v-btn
                color="primary"
                elevation="0"
                outlined
                class="icon-left"
                @click="downloadImage(index)"
              >
                <v-icon>mdi-download</v-icon>
                {{ t('picture.download') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-card elevation="0">
          <v-card-title>{{ t('history.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              :label="t('history.created_at')"
              filled
              :value="formatDateTime(item.created_at)"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              :label="t('history.status')"
              filled
              :value="$t('expenses.data.status.' + item.status)"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              v-if="hasApprovalStatus"
              class="mt-4"
              :label="['approved', 'reimbursed'].includes(item.status) ? t('history.approved_at') : t('history.rejected_at')"
              filled
              :value="formatDateTime(item.approval_date)"
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
               v-if="hasApprovalStatus"
               class="mt-4"
              :label="['approved', 'reimbursed'].includes(item.status) ? t('history.approved_by') : t('history.rejected_by')"
              filled
              :value="item.approved_by.display_name"
              :disabled="true"
              hide-details
            ></v-text-field>
          </v-card-text>
        </v-card>

      </template>

      <template v-if="['rejected', 'reimbursed'].includes(item.status)" v-slot:cancel-button>
        <v-btn
          elevation="0"
          color="primary"
          class="icon-left"
          @click="cancel()"
          text
        >
          <v-icon>mdi-close</v-icon>
          {{ t('cancel') }}
        </v-btn>
      </template>

      <template v-slot:action-buttons>
        <v-btn
          v-if="canEdit"
          elevation="0"
          color="primary"
          class="icon-left"
          @click="reject()"
          outlined
          :disabled="isProcessing"
          :loading="isProcessing"
        >
          <v-icon>mdi-reply</v-icon>
          {{ t('reject') }}
        </v-btn>

        <v-btn
          v-if="canEdit"
          color="primary"
          elevation="0"
          class="icon-left"
          @click="approve()"
          :disabled="isProcessing"
          :loading="isProcessing"
        >
          <v-icon>mdi-check</v-icon>
          {{ t('approve') }}
        </v-btn>

        <v-btn
          v-if="item.can_reimburse"
          color="primary"
          elevation="0"
          class="icon-left"
          @click="setReimbursed()"
        >
          <v-icon>mdi-check</v-icon>
          {{ t('reimburse-action') }}
        </v-btn>
      </template>
    </BasicEditContainer>

    <v-dialog
      v-model="dialogs.reimburse.show"
      width="440"
      persistent
    >
      <expense-edit-reimbursement
        v-if="dataLoaded"
        @cancel="dialogs.reimburse.show = false"
        @save="saveReimburse"
        :initial-amount="dialogs.reimburse.amount"
        :initial-comment="dialogs.reimburse.comment"
        :reimbursement-currency="item.reimbursement_currency"
      ></expense-edit-reimbursement>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { Buffer } from 'buffer'
import loading from '@/components/layouts/Loading'
import toolbar from '@/components/layouts/Navigation'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import CancelButtonDialog from '@/components/layouts/CancelButtonDialog'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import moment from 'moment/moment'
import ExpenseEditReimbursement from '@/views/ExpenseEditReimbursment'

export default {
  name: 'ExpenseEdit',
  components: {
    ExpenseEditReimbursement,
    BasicEditContainer,
    CancelButtonDialog,
    loading,
    toolbar,
    DatePicker
  },
  data() {
    return {
      itemId: null,
      item: null,
      dataLoaded: false,
      approvalComment: '',
      errorLoading: false,
      imageData: null,
      dialogs: {
        reimburse: {
          show: false,
          amount: '',
          comment: ''
        }
      },
      isProcessing: false
    }
  },
  created() {
    this.itemId = this.$route.params.id
    this.loadData()
  },
  computed: {
    canEdit() {
      return this.item && this.item.status === 'filed'
    },
    hasApprovalStatus() {
      return this.item && this.item.status !== 'filed'
    }
  },
  methods: {
    getUserConfirmResponse(response) {
      this.cancelConfirmed = response
    },
    t: function (key) {
      return this.$t('expenses.detail.' + key)
    },
    async loadData() {
      try {
        let response = await HTTP.get('expenses/' + this.itemId)
        this.item = response.data.data
        this.dataLoaded = true
        this.loadDocumentImage()
      } catch (err) {
        this.$root.infoNotification.showError(err.message)
        this.errorLoading = true
        this.dataLoaded = false
      }
    },
    loadDocumentImage() {
      if (this.item.documents) {
        this.imageData = []
        let documents = this.item.documents.split(';')
        documents.forEach(doc => {
          HTTP.get('/expense-documents/' + doc, { responseType: 'arraybuffer' }).then(function (response) {
            this.imageData.push('data:image/jpeg;base64,' + new Buffer(response.data, 'binary').toString('base64'))
          }.bind(this))
        })
      }
    },
    async approve() {
      await this.doAction('approve')
      this.$root.infoNotification.showMessage(this.t('approved-confirm-message'))
    },
    async reject() {
      await this.doAction('reject')
      this.$root.infoNotification.showMessage(this.t('reject-confirm-message'))
    },
    async doAction(action) {
      this.isProcessing = true
      const json = {
        ids: [this.itemId],
        comment: this.approvalComment
      }
      await HTTP.post('expense-' + action, json)
      this.isProcessing = false
      this.cancel()
    },
    cancel() {
      this.$router.push({ name: 'Expenses' })
    },
    downloadImage(index) {
      if (typeof this.imageData[index] === 'undefined') {
        return
      }
      let fileName = "ExpenseDocument_"
          + moment(this.item.created_at).format('YYYY_MM_DD') + "_"
          + this.item.user.firstname + "_"
          + this.item.user.lastname

      const downloadLink = document.createElement("a")
      downloadLink.href = this.imageData[index]
      downloadLink.download = fileName
      downloadLink.click()
    },
    setReimbursed() {
      this.dialogs.reimburse.amount = this.item.status === 'approved' ? Number.parseFloat(this.item.total_amount_incl_vat) : this.item.reimburse_amount
      this.dialogs.reimburse.comment = this.item.approval_comment
      this.dialogs.reimburse.show = true
    },
    async saveReimburse(data) {
      data.id = this.itemId
      await HTTP.post('expense-reimburse', data)
      await this.loadData()
      this.dialogs.reimburse.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.expense-image-wrapper {
  display: flex;
  flex-direction: column;

  & > .layout {
    margin-top: 0;
  }

  .expense-image {
    position: relative;
    max-width: 100%;

    & + .expense-image {
      margin-top: 16px;
    }

    .v-btn {
      position: absolute;
      bottom: 16px;
      left: 16px;
      background: #FFF;
    }
  }
}
</style>


var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.mode==='list')?_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}},[_vm._l((_vm.groups),function(item,i){return [_c('v-list-item',{attrs:{"value":item.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1)]}}],null,true)})]})],2)],1):_vm._e(),(_vm.mode==='dropdown')?_c('v-select',{attrs:{"filled":"","title":_vm.$t('components.permissiongroup-select.select'),"label":_vm.$t('components.permissiongroup-select.select'),"multiple":"","items":_vm.groups,"item-text":"name","item-value":"id","data-cy":_vm.dataCy,"rules":[function () {
            if (_vm.required && _vm.selectedGroups.length === 0) {
                return _vm.$t('validation.required.default');
            }
            return true;
        }],"hide-details":"auto"},on:{"blur":_vm.selectBlur},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ("+_vm._s(data.item.user_count)+") ")]}}],null,false,1999213855),model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import DocumentsIndex from '@/views/DocumentsIndex'
import DocumentsCreateDocument from '@/views/DocumentsCreateDocument'
import DocumentsEditDocument from '@/views/DocumentsEditDocument'
import DocumentsCreateFolder from '@/views/DocumentsCreateFolder'
import DocumentsEditFolder from '@/views/DocumentsEditFolder'

const documentsRoutes = [
    {
        path: '/documents/create-document/:parentFolderId?',
        name: 'DocumentsCreateDocument',
        component: DocumentsCreateDocument,
        meta: {
            accessConfig:  {
              moduleName: ['documents_explorer'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/documents/create-folder/:parentFolderId?',
        name: 'DocumentsCreateFolder',
        component: DocumentsCreateFolder,
        meta: {
            accessConfig:  {
              moduleName: ['documents_explorer'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/documents/edit-document/:id?',
        name: 'DocumentsEditDocument',
        component: DocumentsEditDocument,
        meta: {
            accessConfig:  {
              moduleName: ['documents_explorer'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/documents/edit-folder/:id?',
        name: 'DocumentsEditFolder',
        component: DocumentsEditFolder,
        meta: {
            accessConfig:  {
              moduleName: ['documents_explorer'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/documents/:folderId?',
        name: 'DocumentsIndex',
        component: DocumentsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['documents_explorer'],
                requiresAuth: true
            }
        }
    }
]

export default documentsRoutes

<template>
  <div>
    <basic-edit-container
      :title="t('edit.title')"
      :data-loaded="!loading"
      ref="editor"
      @cancel="close"
      :saveAction="save"
      @saved="onSaved"
      :delete-action="doDelete"
      @deleted="onDeleted"
      :has-changes="hasChanges"
    >
      <v-card elevation="0">
        <v-card-title>{{ t('details.group-title') }}</v-card-title>
        <v-card-text>
          <v-text-field
            :label="t('details.name')"
            v-model="data.title"
            counter="120"
            filled
            :rules="[
              () => !!data.title || $t('validation.required.default'),
              () => (data.title && data.title.length <= 120) || $t('validation.length.default')
            ]"
          ></v-text-field>
        </v-card-text>
      </v-card>

      <v-card
          elevation="0"
          class="sp-mar-top-3"
      >
        <v-card-title>
          <span>{{ t('picture.group-title') }}</span>
        </v-card-title>
        <v-card-text>
          <picture-upload-dialogue v-model="multiUploadFiles" :processing="processing" @finish="finishUpload()"></picture-upload-dialogue>

          <div class="photo-wrapper">
            <div v-for="photo in data.photos" class="photos">
              <image-wrapper
                :link="'/galleries/gallery/' + galleryId + '/' + photo + '?format=640x360'"
                :photo-id="photo"
                @delete="removePhoto"/>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </basic-edit-container>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import PictureUpload from '@/components/inputs/PictureUpload'
import ImageWrapper from '@/components/ImageWrapper'
import PictureUploadDialogue from '@/components/inputs/PictureUploadDialogue'

export default {
  name: 'GalleryEdit',
  components: {
    ImageWrapper,
    PictureUpload,
    BasicEditContainer,
    PictureUploadDialogue
  },
  data() {
    return {
      galleryId: null,
      loading: true,
      data: {
        title: '',
        photos: [],
      },
      multiUploadFiles: [],
      uploaded_picture: null,
      dataOriginal: null,
      processing: false
    }
  },
  async created() {
    this.galleryId = this.$route.params.id
    await this.loadData()
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    },
  },
  watch: {
    multiUploadFiles: function () {
      // this.uploadMultipleFiles()
    },
  },
  methods: {
    finishUpload: function() {
      this.uploadMultipleFiles()
    },
    t: function (key) {
      return this.$t('galleries.gallery.detail.' + key)
    },
    async loadData() {
      this.loading = true
      try {
        let response = await HTTP.get('galleries/gallery/' + this.galleryId)
        this.data = response.data
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
      } catch (e) {
        this.$root.infoNotification.showResponseError(e)
      } finally {
        this.loading = false
      }
    },
    removePhoto(photo) {
      let newArray = this.data.photos.filter(z => z !== photo)
      this.data.photos = [...newArray]
    },
    save() {
      return HTTP.put('galleries/gallery/' + this.galleryId, this.data)
    },
    async onSaved() {
      this.dataOriginal = null
      this.close()
    },
    close() {
      this.$router.back()
    },
    async onPictureUploaded() {
      this.processing = true
      let response = await HTTP.post('galleries/gallery/' + this.galleryId + '/add-photo', { content: this.uploaded_picture })
      this.data.photos.push(response.data.id)
      this.processing = false
    },
    async uploadMultipleFiles() {
      this.processing = true
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      });

      for (const file of this.multiUploadFiles) {
        let fileContent = await toBase64(file)
        let response = await HTTP.post('galleries/gallery/' + this.galleryId + '/add-photo', { content: fileContent })
        this.data.photos.push(response.data.id)
      }

      this.multiUploadFiles = []
      this.processing = false
    },
    doDelete() {
      return HTTP.delete('galleries/gallery/' + this.galleryId)
    },
    async onDeleted() {
      this.close()
    }
  }
}
</script>

<style scoped lang="scss">
.photo-wrapper {
  display: flex;
  gap: 0 16px;
  width: calc(100% + 32px);
  flex-wrap: wrap;
  margin-left: -16px;
  padding: 0 16px 0 16px;

  .photos {
    margin-top: 16px;
  }
}
</style>

<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">
        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">
          
          <app-filter-apply-button
            :has-changes="filterStore.hasChanges"
            :is-initial-state="filterStore.isInitialState"
            :is-loading="loading"
            @click="applyFilter();"
            @reset="filterStore.$reset(); applyFilter();"
          ></app-filter-apply-button>

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="$t('components.customer-notes-filters.from')"
                  v-model="filterStore.filter.workStart"
                  id="dateStart"
                  :clearable="true"
                  data-cy="workStart"
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="$t('components.customer-notes-filters.to')"
                  v-model="filterStore.filter.workEnd"
                  id="dateEnd"
                  :clearable="true"
                  data-cy="workEnd"
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item filter-search-list">
                <app-filter-search-field
                  v-model="filterStore.filter.searchTerm"
                  :filterLabel="$t('components.customer-notes-filters.search-label')"
                  clearable
                  append-icon="mdi-magnify"
                  persistent-placeholder
                  :placeholder="$t('components.customer-notes-filters.search-placeholder')">
                </app-filter-search-field>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item filter-status-filter">
                <select-status-filter-widget 
                  v-model="filterStore.filter.status"
                  :multiple="true"
                  :filter-list="['new', 'completed', 'deleted']"
                ></select-status-filter-widget>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-container>
      </div>

      <v-container fluid>
   
          <v-row class="data-table-toolbar">
            <v-spacer></v-spacer>
            <div v-show="dataLoaded && items.length > 0">
              <v-btn
                outlined
                color="primary"
                elevation="0"
                class="icon-left"
                :disabled="!readyToDownload"
                :loading="isDownloadingExport"
                @click="exportExcel()"
                data-cy="excelButton"
              >
                <v-icon>mdi-download</v-icon>
                {{ $t('customer_notes.list.excel-export') }}
              </v-btn>
            </div>
            <div>
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                :disabled="!readyToDownload"
                data-cy="createButton"
                :to="{ path: '/customer_notes/create'}"
              >
                <v-icon>mdi-plus</v-icon>
                {{ $t('customer_notes.list.create') }}
              </v-btn>
            </div>
        </v-row>

        <v-row v-if="dataLoaded && items.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>
        
        <v-row v-else>

          <v-data-table
            calculate-widths
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            class="elevation-0"
            :sort-by.sync="filterStore.table.sortBy"
            :sort-desc.sync="filterStore.table.sortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.note_date`]="{ item }">
              <span>{{ formatDate(item.note_date) }}</span>
            </template>
           
            <template v-slot:[`item.status`]="{ item }">
              <status-icon-widget :status="item.status" :hide-text="false"/>
            </template>

            <template v-slot:[`item.qm`]="{ item }">
              <v-icon v-if="item.qm == 1">
                mdi-check
              </v-icon>
              <v-tooltip
                v-if="item.qm == 1 && item.qm_note && item.qm_note !== ''" bottom max-width="40%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-comment-eye-outline</v-icon>
                </template>
                <span>{{ item.qm_note }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.proposal`]="{ item }">
              <v-icon v-if="item.proposal == 1">
                mdi-check
              </v-icon>
              <v-tooltip 
                v-if="item.proposal == 1 && item.proposal_notes && item.proposal_notes !== ''" bottom max-width="40%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-comment-eye-outline</v-icon>
                </template>
                <span>{{ item.proposal_notes }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.client_communication_date`]="{ item }">
              <span>{{ formatDate(item.client_communication_date) }}</span>
              <v-tooltip 
                v-if="item.client_communication_date && item.contact_person_notes &&item.contact_person_notes !== ''" bottom max-width="40%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-comment-eye-outline</v-icon>
                </template>
                <span>{{ item.contact_person_notes }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ path: '/customer_notes/' + item.id }" class="edit-row-table" data-cy="editButton">
                <v-icon
                  small
                  class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>

          </v-data-table>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import moment from 'moment'
import toolbar from '@/components/layouts/Navigation'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import SelectStatusFilterWidget from '@/components/filter-components/SelectStatusFilterWidget'
import StatusIconWidget from '@/components/layouts/StatusIconWidget'
import { useCustomerNotesFilterStore } from '@/stores/CustomerNotesFilterStore'

export default {
  name: 'CustomerNotesIndex',
  components: {
    SelectStatusFilterWidget,
    StatusIconWidget,
    toolbar,
    AppFilterApplyButton, 
    AppFilterDatePicker,
    AppFilterSearchField
  },
  setup() {
    const filterStore = useCustomerNotesFilterStore()
    return { filterStore }
  },
  data() {
    return {
      configuration: null,
      loading: false,
      isDownloadingExport: false,
      isExporting: false,
      exportStats: {
        approved: null,
      },
      totalItems: 0,
      items: [],
      options: {},
      dataLoaded: false,
      message: null,
      error: false,
      cancelHTTP: null,
      errorCancel: null,
      status: ''
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.client'),
          align: 'start',
          sortable: true,
          value: 'customers.name',
          width: 250,
          cellClass: 'text-middle'
        }, 
        {
          text: this.t('table.employee'),
          value: 'colleague.name',
          sortable: true,
          width: 150,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.date'),
          value: 'note_date',
          sortable: true,
          width: 120,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.status'),
          value: 'status',
          align: 'right',
          width: 100,
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.qm'),
          value: 'qm',
          width: 180,
          align: 'center',
          sortable: false
        },
        {
          text: this.t('table.proposal'),
          value: 'proposal',
          width: 150,
          align: 'center',
          sortable: false
        },
        {
          text: this.t('table.offer_number'),
          value: 'offer_number',
          width: 180,
          align: 'center',
          sortable: true,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.client_communication_date'),
          value: 'client_communication_date',
          width: 200,
          align: 'center',
          sortable: true,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          width: 115,
          align: 'center',
          sortable: false
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('customer_notes.list.' + key)
    },
    exportExcel() {
      this.isDownloadingExport = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

      HTTP.post('dataexport/excel/customer_notes' + url, {}, { responseType: 'arraybuffer' })
      .then(function (response) {
        let headers = response.headers
        let blob = new Blob([response.data], {type: headers['content-type']})
        let link = document.createElement('a')
        link.style = 'display: none'
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Kundenbemerkung' + moment().format('DDMMYYYY-HHmm') + '.xlsx'
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(blob)
        }, 100)
        this.isDownloadingExport = false
      }.bind(this)).catch(function (error) {
          console.log(error)
        }.bind(this))
    },
    moment: function () {
      return moment()
    },
    applyFilter() {
      this.filterStore.filterApplied()
      this.reload()
    },
    reload() {
      this.$nextTick(() => {
        this.items = []
        this.getDataFromApi()
      })
    },
    getDataFromApi() {
      if (!this.loading) {
        this.loading = true
        this.dataLoaded = false

        this.apiCall().then(data => {
          this.items = data.items
          this.totalItems = data.total
          this.loading = false
          this.dataLoaded = true
        })
      }
    },
    apiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

        HTTP.get('customer_notes' + url).then(function (response) {
          let items = response.data.data;
          let total = response.data.total;

          resolve({
            items,
            total,
          })
        }.bind(this))
      })
    },
    prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.filterStore.filter.workStart) {
        queryStringParts.push('dateStart=' + this.filterStore.filter.workStart)
      }
      if (this.filterStore.filter.workEnd) {
        queryStringParts.push('dateEnd=' + this.filterStore.filter.workEnd)
      }
      if (this.filterStore.filter.searchTerm) {
        queryStringParts.push('q=' + this.filterStore.filter.searchTerm)
      }
      if (this.filterStore.filter.status.length > 0) {
        queryStringParts.push('st=' + this.filterStore.filter.status)
      }
      if (sortBy) {
        queryStringParts.push('sortBy=' + (sortBy[0] ?? ''))
        let direction = sortDesc[0] ? 'desc' : 'asc'
        queryStringParts.push('direction=' + direction)
      }
      page = page ? page : 1
      queryStringParts.push('page=' + page)
      queryStringParts.push('itemsPerPage=' + itemsPerPage)
      return '?' + queryStringParts.join('&')
    },
    readyToDownload: function () {
        return this.dataLoaded && !this.isDownloadingExport
    }
  }
}
</script>

<template>
  <div>
    <BasicNewContainer
      :title="t('create.title')"
      ref="editor"
      @cancel="close"
      :saveAction="save"
      @saved="onSaved"
      :has-changes="hasChanges"
    >
      <v-card elevation="0">
        <v-card-title>{{ t('details.group-title') }}</v-card-title>
        <v-card-text>
          <v-text-field
            :label="t('details.name')"
            v-model="data.title"
            counter="120"
            filled
            :rules="[
              () => !!data.title || $t('validation.required.default'),
              () => (data.title && data.title.length <= 120) || $t('validation.length.default')
            ]"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </BasicNewContainer>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicNewContainer from '@/components/inputs/BasicNewContainer'

export default {
  name: 'GalleryFolderCreate',
  components: {
    BasicNewContainer
  },
  data() {
    return {
      data: {
        title: ''
      },
      parentFolderId: null,
      dataOriginal: null
    }
  },
  created() {
    this.parentFolderId = this.$route.params.parentFolderId
    this.dataOriginal = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    },
  },
  methods: {
    t: function (key) {
      return this.$t('galleries.gallery-folders.detail.' + key)
    },
    save() {
      let data = {...this.data}
      if (this.parentFolderId) {
        data.parentFolderId = this.parentFolderId
      }
      return HTTP.post('galleries/create-folder', data)
    },
    async onSaved() {
      this.dataOriginal = null
      this.close();
    },
    close() {
      this.$router.back()
    }
  }
}
</script>

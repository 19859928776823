<template>
  <div>
    <basic-edit-container
      :title="t('edit.title')"
      ref="editor"
      object-type="Task"
      :object-name="newsData.title"
      :data-loaded="dataLoaded"
      @cancel="cancel"
      :save-action="dataValid ? submit : null"
      @not-executed="showBlockValidation = true"
      :allow-delete="true"
      :delete-action="deleteItem"
      @deleted="onDeleted"
      :has-changes="hasChanges"
    >
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              filled
              label="Titel*"
              counter="120"
              v-model="newsData.title"
              data-cy="title"
              :disabled="readOnly"
              :rules="[
                () => !!newsData.title || $t('validation.required.title'),
                () => (newsData.title && newsData.title.length <= 120) || $t('validation.length.title')
              ]"
            ></v-text-field>

            <v-text-field
              :label="t('details.description')"
              counter="120"
              v-model="newsData.preview_text"
              data-cy="preview_text"
              filled
              :disabled="readOnly"
              :rules="[
                () => !!newsData.preview_text || $t('validation.required.description'),
                () => (newsData.preview_text && newsData.preview_text.length <= 120) || $t('validation.length.description')
              ]"
            ></v-text-field>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('thumbnail.group-title') + '*' }}</v-card-title>
          <v-card-text>
            <div>
              <picture-upload
                v-if="dataLoaded"
                :initial-picture="initialPreviewPicture"
                :show-preview="true"
                :required="true"
                v-model="newsData.new_picture"
                control-id="news-preview"
              ></picture-upload>
            </div>
          </v-card-text>
        </v-card>

        <v-card  v-if="isOldContent" elevation="0" class="sp-mar-top-3 old-content">
          <v-card-title>
            {{ t('old-content-readonly.group-title') }}
            <v-btn
              v-if="!convertToBlocksContent"
              elevation="0"
              color="primary"
              outlined
              class="icon-left"
              @click="convertToBlocksContent = true"
            >
              <v-icon>mdi-refresh</v-icon>
              {{ t('old-content-readonly.add-new-content') }}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <span v-html="newsData.content"></span>
          </v-card-text>
        </v-card>

        <v-card v-if="isNewContent" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.blocks-editor.label') + '*' }}</v-card-title>
          <v-card-text>
            <content-editor content-type="news" v-model="newsData.blocks" :show-block-validation="showBlockValidation" />
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="hasNewBlocks"
              color="primary"
              outlined
              class="icon-left phone-preview"
              @click="showPhonePreview"
            >
              <v-icon>mdi-cellphone-check</v-icon>
              {{ $t('components.blocks-editor.preview') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>

      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ $t('components.category-select.label') + '*' }}</v-card-title>
          <v-card-text>
            <news-category-selector
              v-model="newsData.assigned_category"
              data-cy="assigned_category"
              :read-only="readOnly"
            ></news-category-selector>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
          <v-card-text>
            <permission-group-selector
              v-model="newsData.assigned_permission_groups"
              data-cy="assigned_permission_groups"
              :required="true"
              mode="dropdown"
            ></permission-group-selector>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.post-publish-settings.label') }}</v-card-title>
          <v-card-text>
            <news-publish
              v-if="dataLoaded"
              :initial-status="newsData.status"
              :publish-at="newsData.publish_at"
              :auto-archive-date="newsData.auto_archive_at"
              @status="setStatus"
              @publish-at="setPublishDate"
              @archive-at="setArchiveDate"
              @valid="updatePublishValid"
            ></news-publish>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.post-push-settings.label') }}</v-card-title>
          <v-card-text>
            <news-push
              v-if="dataLoaded"
              v-model="newsData.push"
              data-cy="push"
              :show-last-push="true"
              :last-push="newsInfo.pushed_at"
              :can-push-now="canPushNow"
              :can-configure-push="canConfigurePush"
              :can-push-on-publish="newsDataOriginal && newsDataOriginal.status === 'draft'"
              :push-not-before="minPushDate"
              @valid="updatePushValid"
              @push-now="push"
            ></news-push>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.post-read-analytics.label') }}</v-card-title>
          <v-card-text>
            <analytics-info :get-url="getAnalyticsUrl"></analytics-info>
          </v-card-text>
        </v-card>

        <v-card v-if="hasModule('documents_explorer')" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.link-documents.label') }}</v-card-title>
          <v-card-text>
            <news-document-selector
              :initial-selected-document-ids="newsData.documents"
              v-model="newsData.documents"
              data-cy="documents"
            ></news-document-selector>
          </v-card-text>
        </v-card>
      </template>
    </basic-edit-container>

    <v-dialog
      v-if="showPhonePreviewDialog"
      v-model="showPhonePreviewDialog"
      data-cy="showPhonePreviewDialog"
      width="414"
      content-class="preview-dialog custom-class-dialog"
    >
      <v-card>
        <v-card-title>
          {{ $t('components.blocks-editor.preview') }}
        </v-card-title>
        <v-card-text>
          <content-editor-preview
            :preview-image="newsData.new_picture"
            :blocks="newsData.blocks"
          ></content-editor-preview>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            elevation="0"
            class="icon-left"
            @click="showPhonePreviewDialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
            {{ $t('components.blocks-editor.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP, API } from '@/auth'
import { hasModule } from '@/services/LicenseService'
import moment from 'moment'
import PictureUpload from '@/components/inputs/PictureUpload'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import PermissionGroupSelector from '@/components/PermissionGroupSelector'
import AnalyticsInfo from '@/components/AnalyticsInfo'
import ContentEditor from '@/components/ContentEditor'
import ContentEditorPreview from '@/components/ContentEditorPreview'
import NewsCategorySelector from '@/views/NewsCategorySelector'
import NewsDocumentSelector from '@/views/NewsDocumentSelector'
import NewsPush from '@/views/NewsPush'
import NewsPublish from '@/views/NewsPublish'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'

export default {
  name: 'NewsEdit',
  components: {
    AppFilterSearchField,
    NewsPublish,
    NewsPush,
    NewsDocumentSelector,
    NewsCategorySelector,
    ContentEditorPreview,
    ContentEditor,
    AnalyticsInfo,
    PermissionGroupSelector,
    BasicEditContainer,
    PictureUpload
  },
  data() {
    return {
      itemId: null,
      newsInfo: {
        thumbnail_url: null,
        published_at: null,
        pushed_at: null,
      },
      newsDataOriginal: null,
      newsData: {
        status: null,
        title: null,
        preview_text: null,
        blocks: [],
        videos: [],
        new_picture: null,
        assigned_category: null,
        assigned_permission_groups: [],
        documents: [],
        publish_at: null,
        auto_archive_at: null,
        push: {
          mode: null,
          date: null,
        },
      },
      validation: {
        validPublish: true,
        validPush: true,
      },
      initialPreviewPicture: null,
      dataLoaded: false,
      showPhonePreviewDialog: false,
      convertToBlocksContent: false,
      refreshKey: 0,
      showBlockValidation: false
    }
  },
  async created() {
    this.itemId = this.$route.params.id
    await this.getNewsPost()
  },
  computed: {
    readOnly() {
      return this.newsData.deleted_at != null
    },
    isOldContent() {
      return !this.hasBlocks && !this.convertToBlocksContent
    },
    isNewContent() {
      return this.hasBlocks || this.convertToBlocksContent
    },
    hasChanges() {
      if (!this.newsDataOriginal) {
        return false;
      }
      return !!this.contentChanged
    },
    contentChanged() {
      this.refreshKey
      return JSON.stringify(this.newsData) !== JSON.stringify(this.newsDataOriginal)
    },
    canPushNow() {
      return !this.hasChanges
        && this.newsInfo.published_at !== null
        && this.newsData.status === 'published'
    },
    canConfigurePush() {
      return this.newsData.status === 'publishat'
        || this.newsData.status === 'published'
    },
    minPushDate() {
      return this.newsData.publish_at
        ? this.newsData.publish_at
        : moment()
    },
    hasBlocks() {
      return this.newsDataOriginal && this.newsDataOriginal.blocks.length > 0
    },
    hasNewBlocks() {
      return this.newsData.blocks.length > 0
    },
    dataValid() {
      if (this.dataLoaded) {

        this.showBlockValidation

        if (this.newsData.new_picture && this.newsData.new_picture.length == 0) {
          return false
        }

        if (!this.validation.validPush || !this.validation.validPublish) {
          return false
        }

        if (!this.hasNewBlocks) {
          return false
        }

        let validBlocks = true
        this.newsData.blocks.forEach((block) => {
          if (!block.valid) {
            validBlocks = false
          }
        })
        if (!validBlocks) {
          return false
        }

      }

      return true
    },
  },
  methods: {
    hasModule,
    t: function (key) {
      return this.$t('news.detail.' + key)
    },
    moment: function () {
      return moment()
    },
    imageChanged() {
      this.refreshKey++
    },
    isPublished(publishedAt) {
      return moment().isAfter(publishedAt)
    },
    isArchived(deletedAt) {
      return moment().isAfter(deletedAt)
    },
    async getNewsPost() {
      let response = await HTTP.get('news/' + this.itemId)
      this.newsInfo = response.data.info
      this.newsData = response.data.data

      if (this.newsData.image_exists) {
        this.initialPreviewPicture = '/news/thumb/' + this.itemId
      }

      this.newsData.blocks.forEach(block => {
        block.valid = true
      })

      this.newsDataOriginal = JSON.parse(JSON.stringify(this.newsData))
      this.dataLoaded = true
    },
    getPreviewPictureLink() {
      return this.newsData.image_exists ? API + '/news/thumb/' + this.itemId : ''
    },
    async submit() {
      let formData = new FormData()

      let i = 0
      this.newsData.blocks.forEach(z => {
        z.order = i++
        if (z.name === 'Video' && z.new_content) {
          formData.append('video-' + z.order, z.video)
        }
      })

      formData.append('data', JSON.stringify(this.newsData))

      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }
      await HTTP.post('news/' + this.itemId, formData, config)
      this.close()
    },
    async push() {
      await HTTP.post('news/push/' + this.itemId)
      this.close()
    },
    cancel() {
      this.close()
    },
    close() {
       this.$router.push({ name: 'News' })
    },
    getAnalyticsUrl() {
      return 'analytics/news/' + this.itemId
    },
    showPhonePreview() {
      this.showPhonePreviewDialog = true
    },
    setPublishDate(val) {
      this.newsData.publish_at = moment(val) > moment() ? val : moment()
    },
    setArchiveDate(val) {
      this.newsData.auto_archive_at = val
    },
    setStatus(val) {
      this.newsData.status = val
      if (val === 'draft') {
        this.newsData.push.mode = 'off'
        this.newsData.push.date = null
      }
    },
    updatePublishValid(val) {
      this.validation.validPublish = val
    },
    updatePushValid(val) {
      this.validation.validPush = val
    },
    deleteItem() {
      return HTTP.delete('news/' + this.itemId)
    },
    async onDeleted() {
      this.close()
    }
  }
}
</script>

<style lang="scss">
.old-content .v-card__text {

  & > span > .image-full-width:first-child {
    display: none;

    & + h1 {
      display: none;
    }
  }
}
.scrollable-card-content{
  max-height: calc(100vh - 300px);
  overflow: hidden;
  overflow-y: auto;
}
</style>


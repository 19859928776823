<template>
    <div >
        <div>
            <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>        
          
            <v-container fluid>
                <v-row class="data-table-toolbar">
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn
                            elevation="0"
                            color="primary"
                            class="icon-left"
                            @click="menuCreateDialog = true"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('menu.list.create') }}
                        </v-btn>
                        
                        <menu-create-new-dialog v-model="menuCreateDialog"></menu-create-new-dialog>
                        
                    </div>
                </v-row>

                <v-row v-if="dataLoaded && items.length === 0">
                    <div class="empty-datatable">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                        {{ t('no-data') }}
                    </div>
                </v-row>

                <v-row v-else>
                    <v-data-table
                        :loading="loading"
                        :items-per-page="-1"
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        calculate-widths
                        class="elevation-0"
                    >
                        <template #body="props">
                            <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>   
                            <draggable
                                v-else
                                :list="props.items"
                                tag="tbody"
                                handle=".drag-handle"
                                :disabled="!allowDrag"
                                :move="onMoveCallback"
                                :clone="onCloneCallback"
                                @end="onDropCallback(props.items)"
                            >                  
                                <data-table-row-handler
                                    v-for="(item, index) in props.items"
                                    :key="index"
                                    :item="item"
                                    :headers="headers"
                                    :item-class="getClass(item)"
                                ></data-table-row-handler>
                            </draggable>
                            
                        </template>
                    </v-data-table>
                </v-row>
            </v-container>

            <v-dialog
                v-model="dialog"
                width="500"
                persistent
                content-class="confirm-delete custom-class-dialog"
            >
                <v-card>
                    <v-card-title>
                        {{ t('confirm-delete.title') }}
                    </v-card-title>

                    <v-card-text>
                        {{ $t('menu.list.confirm-delete.content', { moduleName: deleteItem ? deleteItem.title : '' }) }}
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="primary"
                            text
                            class="icon-left"
                            @click="cancelDeletion()"
                        >
                            <v-icon>mdi-close</v-icon>
                            {{ t('confirm-delete.cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            elevation="0"
                            color="primary"
                            class="icon-left"
                            :loading="isDeleting"
                            @click="doDeletion()"
                        >
                            <v-icon>mdi-check</v-icon>
                            {{ t('confirm-delete.ok') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </div>
    </div>
</template>

<script>
import auth from '@/auth/index'
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus'
import toolbar from '@/components/layouts/Navigation'
import moment from 'moment'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import MenuCreateNewDialog from '@/components/dialogs/MenuCreateNewDialog'
import draggable from 'vuedraggable'
import DataTableRowHandler from '@/components/DataTableRowHandler'
import { useMenuFilterStore } from '@/stores/MenuFilterStore'

export default {
    name: 'MenuIndex',
    components: {
        AppFilterApplyButton,
        toolbar,
        MenuCreateNewDialog,
        draggable,
        DataTableRowHandler
    },
    setup() {
        const filterStore = useMenuFilterStore()
        return { filterStore }
    },
    data() {
        return {
            loading: false,
            dataLoaded: false,
            items: [],
            menuCreateDialog: false,
            allowDrag: true,
            dialog: false,
            deleteItem: null,
            isDeleting: false
        }
    },
    mounted() {
        EventBus.$on('delete-item', function (item) {
            this.deleteItem = item
            this.dialog = true
        }.bind(this))
    },
    created() {
        this.getDataFromApi()
    },
    beforeDestroy() {
        EventBus.$off('delete-item')
    },
    computed: {
        headers() {
            return [
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                    value: 'drag',
                    width: 40
                },
                {
                    text: this.t('table.icon'),
                    align: 'center',
                    sortable: false,
                    value: 'icon',
                    width: 60
                },
                {
                    text: this.t('table.title'),
                    align: 'left',
                    sortable: false,
                    value: 'title'
                },
                {
                    text: this.t('table.type'),
                    align: 'center',
                    sortable: false,
                    value: 'type',
                    width: 120
                },
                {
                    text: this.t('table.edit'),
                    value: 'edit',
                    width: 120,
                    align: 'center',
                    sortable: false
                },
                {
                    text: this.t('table.delete'),
                    value: 'delete',
                    width: 120,
                    align: 'center',
                    sortable: false
                }
            ]
        }
    },
    methods: {     
        getClass(item) {
            return item.calories > 500
                ? "cal-high"
                : item.calories > 400
                ? "cal-medium"
                : "cal-low";
        },
        onCloneCallback(item) {
            // Create a fresh copy of item
            const cloneMe = JSON.parse(JSON.stringify(item))
            return cloneMe
        },
        onMoveCallback(evt, originalEvent) {
            const item = evt.draggedContext.element;
            const itemIdx = evt.draggedContext.futureIndex

            if (item.locked) {
                return false
            }

            return true
        },
        async onDropCallback(items) {
            let url = '/menu-sort'
            url += "?language=" + localStorage.getItem("currentLocale")

            await HTTP.post(url, items).then(function () {
                EventBus.$emit('module-config-changed')
            }.bind(this))
        },
        t: function (key) {
            return this.$t('menu.list.' + key)
        },
        moment: function () {
            return moment()
        },
        reload() {
            this.$nextTick(() => {
                this.items = []
                this.getDataFromApi()
            })
        },
        getDataFromApi() {
            if (!this.loading) {
                this.loading = true
                this.dataLoaded = false

                this.apiCall().then(data => {
                    this.items = data.items
                    this.loading = false
                    this.dataLoaded = true
                })
            }
        },
        apiCall() {
            return new Promise((resolve, reject) => {
                let url = "?language=" + localStorage.getItem("currentLocale")

                HTTP.get('menus' + url).then(function (response) {
                    let items = response.data
                    resolve({
                        items,
                    })
                }.bind(this))
            })
        },
        doDeletion() {
            try {
                this.isDeleting = true
                HTTP.delete('menus/' + this.deleteItem.id).then(function () {
                    this.isDeleting = false
                    this.dialog = false
                    this.items = []
                    this.getDataFromApi()
                    auth.loadConfig()
                }.bind(this))
            } catch (error) {
                console.error('Error deleting item:', error)
            }
        },
        cancelDeletion() {
            this.dialog = false
            this.deleteItem = null
        }
    }
}
</script>

<style scoped lang="scss">
.table-loader {
    padding: 0 !important;
    height: auto !important;
  }
</style>

<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">
        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">

          <app-filter-apply-button
            :has-changes="filterStore.hasChanges"
            :is-initial-state="filterStore.isInitialState"
            :is-loading="loading"
            @click="applyFilter();"
            @reset="filterStore.$reset(); applyFilter();"
          ></app-filter-apply-button>

          <v-slide-group show-arrows>
            <v-slide-item class="filter-reason-filter">
              <servappt-area-filter v-model="filterStore.filter.area"/>
            </v-slide-item>

            <v-slide-item>
              <div>
                <div class="filter-item">
                  <app-filter-date-picker
                    :filterLabel="t('from')"
                    v-model="filterStore.filter.dateStart"
                    id="dateStart" 
                    clearable
                  ></app-filter-date-picker>
                </div>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="t('to')"
                  v-model="filterStore.filter.dateEnd"
                  id="dateEnd"
                  clearable
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item filter-status-filter">
                <select-status-filter-widget 
                  v-model="filterStore.filter.status"
                  :multiple="true"
                  :filter-list="['draft', 'published', 'archived', 'deleted']"
                ></select-status-filter-widget>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-container>
      </div>
    </div>

    <v-container fluid>

      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="{ path: '/servappt/requests/create'}"
        >
          <v-icon>mdi-plus</v-icon>
          {{ t('create') }}
        </v-btn>
      </v-row>

      <v-row v-if="dataLoaded && items.length === 0">
        <div class="empty-datatable">
          <v-icon>mdi-alert-circle-outline</v-icon>
          {{ t('no-data') }}
        </div>
      </v-row>

      <v-row v-else>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          class="elevation-0"
          :sort-by="initialSortBy"
          :sort-desc="initialSortDesc"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-options': [25, 50, 100, 200],
            pageText: '{0}-{1} von {2}',
            'items-per-page-text': t('table.items-per-page')
          }"
        >
          <template v-slot:[`item.area.name`]="{ item }">
            <span class="ellipsis"><b>{{ item.area.name }}</b></span>
          </template>

          <template v-slot:[`item.begin`]="{ item }">
            <span class="ellipsis">{{ item.begin | momentDate }}</span>
          </template>

          <template v-slot:[`item.end`]="{ item }">
            <span class="ellipsis">{{ item.end | momentDate }}</span>
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <status-icon-widget :status="item.state" :hide-text="false"/>
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <router-link :to="{ path: '/servappt/requests/' + item.id }" class="edit-row-table">
              <v-icon
                small
                class="edit-row-button"
              >
                edit
              </v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import moment from 'moment'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import ServapptAreaFilter from '@/components/filter-components/ServapptAreaFilter'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import SelectStatusFilterWidget from '@/components/filter-components/SelectStatusFilterWidget'
import StatusIconWidget from '@/components/layouts/StatusIconWidget'
import { useServapptRequestsFilterStore } from '@/stores/ServapptRequestsFilterStore'

export default {
  name: 'ServapptRequestsIndex',
  components: {
    ServapptAreaFilter,
    AppFilterDatePicker,
    AppFilterApplyButton,
    toolbar,
    StatusIconWidget,
    SelectStatusFilterWidget
  },
  setup() {
    const filterStore = useServapptRequestsFilterStore()
    return { filterStore }
  },
  data() {
    return {
      items: [],
      totalItems: 0,
      dataLoaded: false,
      error: false,
      loading: false,
      options: {},
      initialSortBy: 'id',
      initialSortDesc: false,
    }
  },
  watch: {
    options: {
      handler() {
        this.getItems()
      },
      deep: true,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.area'),
          align: 'start',
          sortable: true,
          value: 'area.name',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.begin'),
          align: 'start',
          sortable: true,
          value: 'begin'
        },
        {
          text: this.t('table.end'),
          align: 'start',
          sortable: true,
          value: 'end',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.state'),
          align: 'start',
          sortable: true,
          value: 'state',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          cellClass: 'text-middle'
        }
      ]
    }
  },
  methods: {
    t: function (key) {
      return this.$t('servappt.requests.list.' + key)
    },
    buildFilter() {
      let filter = this.filterStore.filter
      return {
        from: filter.dateStart,
        to: filter.dateEnd,
        userid: filter.selectedUser?.id,
        state: filter.state,
      }
    },
    reload() {
      this.$nextTick(() => {
        this.items = []
        this.getItems()
      })
    },
    applyFilter() {
      this.filterStore.filterApplied()
      this.reload()
    },
    getItems() {
      if (!this.loading) {
        this.loading = true
        this.dataLoaded = false

        this.apiCall().then(data => {
          this.items = data.items
          this.totalItems = data.total
          this.loading = false
        })
      }
    },
    apiCall() {
      return new Promise((resolve, reject) => {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options

        let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

        HTTP.get('servappt/requests' + url).then(function (response) {
          let items = response.data.data
          let total = response.data.total

          resolve({
            items,
            total,
          })
        }.bind(this))

      })
    },
    prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.filterStore.filter.dateStart) {
        queryStringParts.push('dateStart=' + this.filterStore.filter.dateStart)
      }
      if (this.filterStore.filter.dateEnd) {
        queryStringParts.push('dateEnd=' + this.filterStore.filter.dateEnd)
      }
      if (this.filterStore.filter.area) {
        queryStringParts.push('area=' + this.filterStore.filter.area)
      }
      if (this.filterStore.filter.state.length > 0) {
        queryStringParts.push('st=' + this.filterStore.filter.state)
      }
      if (sortBy) {
        queryStringParts.push('sortBy[]=' + (sortBy[0] ?? ''))
        queryStringParts.push('sortDesc[]=' + sortDesc[0])
      }
      page = page ? page : 1
      queryStringParts.push('page=' + page)
      queryStringParts.push('itemsPerPage=' + itemsPerPage)
      return '?' + queryStringParts.join('&')
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm')
    }
  }
}
</script>

<style language="scss" scoped>
.ellipsis {
  display: block;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>

import { render, staticRenderFns } from "./DataTableRowHandler.vue?vue&type=template&id=00490998&scoped=true"
import script from "./DataTableRowHandler.vue?vue&type=script&lang=js"
export * from "./DataTableRowHandler.vue?vue&type=script&lang=js"
import style0 from "./DataTableRowHandler.vue?vue&type=style&index=0&id=00490998&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00490998",
  null
  
)

export default component.exports
<template>
  <div>
    <basic-new-container
      :title="t('folder.title-create')"
      ref="editor"
      @cancel="close"
      :saveAction="submit"
      @saved="onSaved"
      :has-changes="hasChanges"
    >
      <template v-slot:default>

        <v-card elevation="0">
          <v-card-title>{{ t('folder.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              :label="t('folder.folder-name')"
              counter="120"
              v-model="data.name"
              filled
              :rules="[
                () => !!data.name || $t('validation.required.default'),
                () => (data.name && data.name.length <= 120) || $t('validation.length.default')
              ]"
            ></v-text-field>
          </v-card-text>
        </v-card>

      </template>

      <template v-slot:meta>

        <v-card elevation="0">
          <v-card-title>{{ $t('components.permissiongroup-select.label') }}</v-card-title>
          <v-card-text>
            <permission-group-selector
              v-model="data.permission_groups"
              :required="true"
              mode="dropdown"
            ></permission-group-selector>
          </v-card-text>
        </v-card>

      </template>

    </basic-new-container>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicNewContainer from '@/components/inputs/BasicNewContainer'
import PermissionGroupSelector from '@/components/PermissionGroupSelector'

export default {
  name: 'DocumentsCreateFolder',
  components: {
    BasicNewContainer,
    PermissionGroupSelector
  },
  data() {
    return {
      dataOriginal: null,
      data: {
        name: '',
        permission_groups: [],
        targetDir: 'documents'
      },
      currentDir: null
    }
  },
  created() {
    if (this.$route.params.parentFolderId) {
      this.currentDir = this.$route.params.parentFolderId
      this.data.targetDir = this.currentDir
    }
    this.dataOriginal = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    }
  },
  methods: {
    t: function (key) {
      return this.$t('documents.detail.' + key)
    },
    submit() {
      let data = { ...this.data }

      if (this.currentDir) {
        this.data.parent_folder_id = this.currentDir
      }
     
      return HTTP.post('documents/folders', this.data)
    },
    async onSaved() {
      this.dataOriginal = null
      this.close()
    },
    close() {
      if (this.currentDir) {
        this.$router.push({ name: 'DocumentsIndex', params: { folderId: this.currentDir } })
      } else {
        this.$router.push({ name: 'DocumentsIndex' })
      }
    }
  }
}
</script>

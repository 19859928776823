<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="edit-screen no-hero-container"> 

      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          color="primary"
          class="icon-left"
          :to="{ path: '/settings/shop-categories/create'}"
        >
          <v-icon>mdi-plus</v-icon>
          {{ t('create') }}
        </v-btn>
      </v-row>

      <v-row v-if="dataLoaded && categories.data.length === 0">
        <div class="empty-datatable">
          <v-icon>mdi-alert-circle-outline</v-icon>
          {{ t('no-data') }}
        </div>
      </v-row>

      <v-row v-else>

        <v-data-table
          :headers="headers"
          :items="categories.data"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          class="elevation-0"
          :sort-by="initialSortBy"
          :sort-desc="initialSortDesc"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-options': [25, 50, 100, 200],
            pageText: '{0}-{1} von {2}',
            'items-per-page-text': t('table.items-per-page')
          }"
        >
          <template #body="props">
            <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
            <draggable
              v-else
              :list="props.items"
              tag="tbody"
              :move="onMoveCallback"
              :clone="onCloneCallback"
              @end="onDropCallback(props.items)"
            >
            <data-table-shop-categories-row-handler
              v-for="(item, index) in props.items"
              :key="item.id"
              :row-index="index"
              :item="item"
              :headers="headers"
            >
              <template v-slot:[`item.status`]="{ item }">
                <status-icon-widget :status="item.status" :hide-text="false" />
              </template>
            </data-table-shop-categories-row-handler>
            </draggable>
          </template>
        </v-data-table>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import draggable from 'vuedraggable'
import DataTableShopCategoriesRowHandler from '@/components/DataTableShopCategoriesRowHandler'
import StatusIconWidget from '@/components/layouts/StatusIconWidget'

export default {
  name: 'ShopCategoriesIndex',
  components: {
    toolbar,
    DataTableShopCategoriesRowHandler,
    draggable,
    StatusIconWidget
  },
  data() {
    return {
      categories: {
        page: 1,
        current_page: 1,
        last_page: 1,
        total: null,
        data: []
      },
      totalItems: null,
      dataLoaded: false,
      loading: false,
      error: false,
      options: {},
      initialSortBy: 'name',
      initialSortDesc: false,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'drag',
          width: 30
        },
        {
          text: this.t('table.name'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: this.t('table.status'),
          align: 'start',
          sortable: false,
          value: 'status',
          width: 100
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 100
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getCategories()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('shop.categories.list.' + key)
    },
    getCategories() {
      this.loading = true
      HTTP.get('shop_categories', { params: this.options }).then(function (response) {
        this.categories.data = response.data.data
        this.totalItems = response.data.total
        this.categories.last_page = response.data.last_page
        this.categories.current_page = response.data.current_page
        this.categories.total = response.data.total
        this.dataLoaded = true
        this.loading = false
        this.error = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    onCloneCallback(item) {
      const cloneMe = JSON.parse(JSON.stringify(item))
      return cloneMe
    },
    onMoveCallback(evt) {
        const item = evt.draggedContext.element
        const itemIdx = evt.draggedContext.futureIndex
        if (item.locked) {
          return false
        }
        return true
      },
    async onDropCallback(items) {
        await HTTP.post('shop_categories_sort', items).then(function () {
          this.$root.infoNotification.showMessage(this.$t('instructions.list.sorting-sucessful'))
        }.bind(this))
    }
  }
}
</script>

<style scoped lang="scss">
.table-loader {
  padding: 0 !important;
  height: auto !important;
}
</style>

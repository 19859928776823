<template>
  <div>
    <basic-edit-container
      :title="t('folder.title-edit')"
      :has-changes="hasChanges"
      :data-loaded="dataLoaded"
      :delete-action="deleteItem"
      :save-action="save"
      @saved="onSaved"
      @cancel="cancel"
    >
      <template v-slot:default>

        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              :label="t('details.title') + '*'"
              counter="120"
              v-model="data.name"
              filled
              :rules="[
                () => !!data.name || $t('validation.required.default'),
                () => (data.name && data.name.length <= 120) || $t('validation.length.default')
              ]"
            ></v-text-field>
          </v-card-text>
        </v-card>

      </template>

      <template v-slot:meta>

        <v-card elevation="0">
          <v-card-title>{{ $t('components.permissiongroup-select.label') }}</v-card-title>
          <v-card-text>
            <permission-group-selector
              v-model="data.assigned_permission_groups"
              :required="true"
              mode="dropdown"
            ></permission-group-selector>
          </v-card-text>
        </v-card>

      </template>
      
    </basic-edit-container>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import PermissionGroupSelector from '@/components/PermissionGroupSelector'

export default {
  name: 'DocumentsEditFolder',
  components: { 
    BasicEditContainer,
    PermissionGroupSelector
  },
  data() {
    return {
      dataOriginal: null,
      data: {
        name: '',
        assigned_permission_groups: []
      },
      dataLoaded: false,
      currentFolderId: null
    }
  },
  created() {
    if (this.$route.params.id) {
      this.currentFolderId = this.$route.params.id
    }
    this.getFolder()
  },
  computed: {
    hasChanges() {
      return  JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    }
  },
  methods: {
    t: function (key) {
      return this.$t('documents.detail.' + key)
    },
    async getFolder() {
      let response = await HTTP.get('documents/folders/' + this.currentFolderId)
      this.data = response.data
      const tempPermGroupsIds = []
      this.data.assigned_permission_groups.forEach((element) => {
        tempPermGroupsIds.push(element.permission_group_id)
      })
      this.data.assigned_permission_groups = tempPermGroupsIds

      this.dataOriginal = JSON.parse(JSON.stringify(this.data))
      this.dataLoaded = true
    },
    async save() {
      HTTP.patch('documents/folders/' + this.currentFolderId, this.data)
        .then(function () {
          this.close()
          this.processing = false
        }.bind(this)).catch(function (error) {
          console.log(error.response)
        }.bind(this))
    },
    close() {
      this.$router.back()
    },
    cancel() {
      this.close()
    },
    async deleteItem() {
      await HTTP.delete('documents/folders/' + this.itemId)
      this.close()
    },
    onSaved() {
      this.close()
    }
  }
}
</script>

<template>
  <div>
    <BasicNewContainer
      :title="t('document.title-create')"
      ref="editor"
      @cancel="close"
      :saveAction="submit"
      @saved="onSaved"
      :has-changes="hasChanges"
    >
      <template v-slot:default>

        <v-card elevation="0">
          <v-card-title>{{ t('document.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              :label="t('document.document-name')"
              counter="120"
              v-model="data.title"
              filled
              :rules="[
                () => !!data.title || $t('validation.required.default'),
                () => (data.title && data.title.length <= 120) || $t('validation.length.default')
              ]"
            ></v-text-field>

            <v-text-field
              :label="t('document.description')"
              counter="120"
              v-model="data.description"
              filled
              :rules="[
                () => data.description.length <= 120 || $t('validation.length.description')
              ]"
            ></v-text-field>

            <v-file-input
              :label="t('document.choose-file')"
              truncate-length="40"
              show-size
              filled
              v-model="inputDocumentFile"
              :rules="[
                () => !!inputDocumentFile || $t('validation.required.default')
              ]"
            ></v-file-input>

            <v-text-field
                :label="t('document.keywords')"
                v-model="data.keywords"
                filled
                :hint="t('document.keywords-hint')"
                class="has-hint-message"
            ></v-text-field>
          </v-card-text>
        </v-card>

      </template>

      <template v-slot:meta>

        <v-card elevation="0">
          <v-card-title>{{ $t('components.permissiongroup-select.label') }}</v-card-title>
          <v-card-text>
            <permission-group-selector
              v-model="data.permission_groups"
              :required="true"
              mode="dropdown"
            ></permission-group-selector>
          </v-card-text>
        </v-card>

      </template>

    </BasicNewContainer>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicNewContainer from '@/components/inputs/BasicNewContainer'
import PermissionGroupSelector from '@/components/PermissionGroupSelector'

export default {
  name: 'DocumentsCreateDocument',
  components: {
    BasicNewContainer,
    PermissionGroupSelector
  },
  data() {
    return {
      dataOriginal: null,
      data: {
        title: '',
        description: '',
        permission_groups: [],
        keywords: null,
        targetDir: null
      },
      currentDir: null,
      inputDocumentFile: null
    }
  },
  created() {
    if (this.$route.params.parentFolderId) {
      this.currentDir = this.$route.params.parentFolderId
      this.data.targetDir = this.currentDir
    }
    this.dataOriginal = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    }
  },
  methods: {
    t: function (key) {
      return this.$t('documents.detail.' + key)
    },
    submit() {
      let data = { ...this.data }

      if (this.currentDir) {
        this.data.parent_folder_id = this.currentDir
      }

      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }

      let formData = new FormData()
      if (this.inputDocumentFile) {
        formData.append('document', this.inputDocumentFile)
      }

      formData.append('data', JSON.stringify(data))

      return HTTP.post('documents', formData, config)
    },
    async onSaved() {
      this.dataOriginal = null
      this.close()
    },
    close() {
      if (this.currentDir) {
        this.$router.push({ name: 'DocumentsIndex', params: { folderId: this.currentDir } })
      } else {
        this.$router.push({ name: 'DocumentsIndex' })
      }
    }
  }
}
</script>

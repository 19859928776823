var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('toolbar',{attrs:{"dataLoaded":true}},[_vm._v(_vm._s(_vm.t('title')))]),_c('v-container',{staticClass:"edit-screen no-hero-container",attrs:{"fluid":""}},[_c('v-row',{staticClass:"data-table-toolbar"},[_c('v-spacer'),_c('v-btn',{staticClass:"icon-left",attrs:{"elevation":"0","color":"primary","to":{ path: '/settings/shop-categories/create'}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.t('create'))+" ")],1)],1),(_vm.dataLoaded && _vm.categories.data.length === 0)?_c('v-row',[_c('div',{staticClass:"empty-datatable"},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.t('no-data'))+" ")],1)]):_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.categories.data,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":_vm.initialSortBy,"sort-desc":_vm.initialSortDesc,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          'items-per-page-options': [25, 50, 100, 200],
          pageText: '{0}-{1} von {2}',
          'items-per-page-text': _vm.t('table.items-per-page')
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(props){return [(_vm.loading)?_c('tbody',[_c('tr',[_c('td',{staticClass:"table-loader",attrs:{"colspan":"6"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)])]):_c('draggable',{attrs:{"list":props.items,"tag":"tbody","move":_vm.onMoveCallback,"clone":_vm.onCloneCallback},on:{"end":function($event){return _vm.onDropCallback(props.items)}}},_vm._l((props.items),function(item,index){return _c('data-table-shop-categories-row-handler',{key:item.id,attrs:{"row-index":index,"item":item,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('status-icon-widget',{attrs:{"status":item.status,"hide-text":false}})]}}],null,true)})}),1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }